import { Typography } from '@mui/material'
import React, { useMemo } from 'react'

//styles
import './PointInput.css'
//types
import { PointInputProps } from 'types'
//utils
import { convertTo_K_M_B_Format, formatNumber } from 'utils/utils'

const PointInput = ({
  onClick,
  points,
  isPig,
  customInnerClassName = '',
  customClassName = '',
  inputVariant = 'h4',
  inputRef,
  formatInKMB = false,
}: PointInputProps) => {
  const formattedPoints = useMemo(
    () => (formatInKMB ? convertTo_K_M_B_Format(points) : formatNumber(points)),
    [formatInKMB, points]
  )

  return (
    <div
      className={`pointInputInner ${customInnerClassName} ${isPig ? 'pig' : 'coin'}`}
      onClick={onClick}
    >
      <div className={`pointInput ${customClassName} ${isPig ? 'pig' : 'coin'}`}>
        <Typography
          variant={inputVariant}
          sx={styles.value}
          className={`priceText ${isPig ? 'pig' : 'coin'}`}
          ref={inputRef}
        >
          {formattedPoints}
        </Typography>
        <img src={`/images/${isPig ? 'pig' : 'coin'}.png`} className="pointInputImage" />
      </div>
    </div>
  )
}

const styles = {
  value: {
    fontWeight: 700,
  },
}

export default PointInput
