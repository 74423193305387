import { useMemo } from 'react'
import { Typography } from '@mui/material'

//assets
import Cart from 'assets/offers/cart.png'
//components
import SpendingModalContent from '../ModalContent/Spending'
import Task from 'components/Task'
//styles
import './index.css'
//types
import { OfferSpendingRewardsProps } from 'types'
//utils
import { Info } from 'utils/icons'

const OfferSpendingRewards = ({ isLocked, offer, showModal }: OfferSpendingRewardsProps) => {
  const rewardData = useMemo(() => {
    const dollarsRemaining = offer.eligibleSpendAmount - (offer?.dollarsSpent ?? 0)

    const coinsRemaining = Math.floor(offer.coinsPerDollar * dollarsRemaining)
    const piggyBanksRemaining = Math.floor(offer.piggyPerDollar * dollarsRemaining)

    const progress = Math.round((1 - dollarsRemaining / offer.eligibleSpendAmount) * 100)

    return { dollarsRemaining, coinsRemaining, piggyBanksRemaining, progress }
  }, [offer])

  const renderModalContent = () => (
    <SpendingModalContent
      coins={offer.coinsPerDollar}
      piggies={offer.piggyPerDollar}
      finalCoins={rewardData.coinsRemaining}
      finalPiggies={rewardData.piggyBanksRemaining}
    />
  )

  const handleShowModal = () => {
    showModal({
      title: 'Offer Spending Rewards',
      text: renderModalContent(),
      titleVariant: 'title',
      isCustomText: true,
      actionButtons: <></>,
      closeButton: true,
    })
  }

  return (
    <div className="gameDetailsSpecialOfferSpendingRewards">
      <Typography variant="h3" className="gameDetailsSpecialOfferSpendingRewardsHeader">
        <span>Spending Rewards ({offer.piggyPerDollar}% cashback)</span>{' '}
        <Info onClick={handleShowModal} />
      </Typography>
      <Task
        icon={<img src={Cart} />}
        title={`Spend up to $${offer.eligibleSpendAmount.toFixed(2)}`}
        subTitle={!isLocked ? `$${rewardData.dollarsRemaining.toFixed(2)} spend remaining` : null}
        coins={rewardData.coinsRemaining}
        piggies={rewardData.piggyBanksRemaining}
        progress={rewardData.progress}
        isLocked={isLocked}
        customClassName="blanketCashback"
      />
    </div>
  )
}

export default OfferSpendingRewards
