import React, { useContext, useState } from 'react'
import { TextField, Typography } from '@mui/material'
import toast from 'react-hot-toast'
import { UserFeedbackUpdateType } from '@playback-rewards/shared-libraries'

//constants
import { REACT_NATIVE_STATUS } from 'constants/index'
//contexts
import AuthContext from 'context/AuthContext'
//components
import BottomButton from 'components/common/BottomButton'
//services
import { saveUserFeedback } from 'services/userFeedbackService'
//types
import { TextFeedbackContentProps } from 'types'
//utils
import { sendRNMessage } from 'utils/utils'

export const TextFeedbackContent = ({ hideModal }: TextFeedbackContentProps) => {
  const { updateUserData } = useContext(AuthContext)
  const [text, setText] = useState('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 2000) {
      setText(event.target.value)
    }
  }

  const handleTextFeedbackSend = () => {
    hideModal()
    saveUserFeedback({ type: UserFeedbackUpdateType.TextFeedback, feedback: text }).then((res) => {
      if (res.data.isUserUpdated) {
        updateUserData(res.data.updatedUser)
      }
    })
    toast.success('Feedback submitted. Thanks!')
  }

  return (
    <>
      <Typography variant="h1">Tell us more</Typography>
      <Typography variant="body1">Your feedback is extremely valuable to us!</Typography>
      <TextField
        placeholder="Enter feedback here"
        multiline
        rows={9}
        fullWidth
        variant="outlined"
        value={text}
        onChange={handleChange}
        inputProps={{
          maxLength: 2000,
          onTouchStart: () => {
            sendRNMessage({ status: REACT_NATIVE_STATUS.ENABLE_INNER_SCROLL, enabled: false })
          },
          onTouchEnd: () => {
            sendRNMessage({ status: REACT_NATIVE_STATUS.ENABLE_INNER_SCROLL, enabled: true })
          },
        }}
        sx={styles.textArea}
      />

      <BottomButton className="actionButton" handleClick={handleTextFeedbackSend}>
        Submit Feedback
      </BottomButton>
    </>
  )
}

const styles = {
  textArea: {
    '& .MuiInputBase-input': {
      textAlign: 'left',
    },
  },
}
