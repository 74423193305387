import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { CurrencyType } from '@playback-rewards/shared-libraries'

//components
import DailyStreakExpiring from './DailyStreakExpiring'
import DailyStreakEnd from './DailyStreakEnd'

const DailyStreakExpiringEndPopup = ({
  open,
  streakCount,
  streakExpiresIn,
  userPigs,
  userCoins,
  extensionPiggiesCost,
  extensionCoinsCost,
  onCurrencyClick,
  onConfirmClick,
  onClose,
}: {
  open: boolean
  streakCount: number
  streakExpiresIn: string
  userPigs: number
  userCoins: number
  extensionPiggiesCost: number
  extensionCoinsCost: number
  onCurrencyClick: (currencyType: CurrencyType) => void
  onConfirmClick: () => void
  onClose: () => void
}) => {
  const [showEndPopup, setShowEndPopup] = useState(false)

  useEffect(() => {
    setShowEndPopup(false)
  }, [open])

  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {!showEndPopup ? (
        <DailyStreakExpiring
          streakCount={streakCount}
          streakExpiresIn={streakExpiresIn}
          userPigs={userPigs}
          userCoins={userCoins}
          extensionPiggiesCost={extensionPiggiesCost}
          extensionCoinsCost={extensionCoinsCost}
          onClose={onClose}
          onCurrencyClick={onCurrencyClick}
          onEndClick={() => setShowEndPopup(true)}
        />
      ) : (
        <DailyStreakEnd
          streakCount={streakCount}
          onClose={onClose}
          onBackClick={() => setShowEndPopup(false)}
          onConfirmClick={onConfirmClick}
        />
      )}
    </Modal>
  )
}

export default DailyStreakExpiringEndPopup
