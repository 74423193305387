import { useState } from 'react'
import Modal from 'components/modal'
import { Box, DialogContent, Typography } from '@mui/material'
import { Info, LargeArrowUp, LightBulbIcon } from 'utils/icons'

import { OFFER_QUALITY } from '../../../constants'

const RarityInfoModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Box
      display="flex"
      onClick={(e) => {
        // stop the click event from bubbling up because there is an event listener above this that is listening for click events that will open/close Featured Games section when there is more than two active offers.  This click event is solely to open/close the modal.
        e.stopPropagation()
      }}
    >
      <button onClick={toggleModal} className="gamesListInfoButton">
        <Info />
      </button>
      {isOpen && <InfoModal showModal={isOpen} toggleModal={toggleModal} />}
    </Box>
  )
}

function InfoModal({ showModal, toggleModal }: { showModal: boolean; toggleModal: () => void }) {
  const piggyBanks = Object.values(OFFER_QUALITY)
    .map(({ name, color }, idx) => (
      <Box key={name} sx={{ ...styles.infoBoxPig, background: color }}>
        {new Array(idx + 1).fill(null).map((_, idx) => (
          <img
            key={idx}
            src="/images/pig.png"
            style={{
              width: '18px',
              height: '16px',
              marginLeft: '-3px',
              mixBlendMode: 'luminosity',
            }}
          />
        ))}
      </Box>
    ))
    .reverse()

  return (
    <Modal
      showModal={showModal}
      hideModal={toggleModal}
      closeButton={true}
      title="Featured Games"
      isCustomText={true}
      titleVariant="title"
      text={
        <DialogContent sx={styles.dialogContent}>
          <Typography sx={styles.text} variant="body2">
            Earn the most <img src="/images/pig.png" /> per hour by completing the highest value
            offers.
          </Typography>
          <Typography sx={styles.text} variant="body2">
            Featured games will always have the highest value offers.
          </Typography>
          <Box sx={styles.infoBoxContainer}>
            <LightBulbIcon />
            <Box sx={styles.infoBoxContent}>
              <Box sx={styles.infoBoxRarity}>
                <Typography variant="body2" sx={styles.infoBoxText}>
                  Highest
                </Typography>
                <LargeArrowUp />
                <Typography variant="body2" sx={styles.infoBoxText}>
                  Lowest
                </Typography>
              </Box>
              <Box sx={styles.infoBoxPigsContainer}>{piggyBanks}</Box>
            </Box>
          </Box>
        </DialogContent>
      }
    />
  )
}

const styles = {
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '21px',
  },
  text: {
    fontWeight: 500,
    color: '#0F172A',
    lineHeight: {
      xs: '22px',
    },
    fontSize: {
      xs: '14px',
    },
  },
  infoBoxContainer: {
    display: 'flex',
    gap: '27px',
    borderRadius: '20px',
    border: '1px solid transparent',
    background:
      'linear-gradient(#f1f5f9, #f1f5f9) padding-box, var(--pink-to-gold-gradient) border-box',
    padding: '8px 8px 15px 8px',
  },
  infoBoxContent: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
    gap: '35px',
  },
  infoBoxRarity: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '3px',
  },
  infoBoxPigsContainer: {
    display: 'flex',
    gap: '5px',
    flexDirection: 'column',
  },
  infoBoxPig: {
    display: 'flex',
    padding: '6px 8px',
    borderRadius: '100px',
    width: 'fit-content',
  },
  infoBoxText: {
    fontWeight: 400,
    fontSize: {
      xs: '14px',
    },
    color: '#00000',
  },
}

export default RarityInfoModal
