import React, { useMemo, useState } from 'react'
import { CurrencyType } from '@playback-rewards/shared-libraries'

//components
import FireIcon from '../FireIcon'
//styles
import './index.css'
//utils
import { formatNumber } from 'utils/utils'

const DailyStreakReviveButton = ({
  streakCount,
  currencyType,
  extensionPiggiesCost,
  extensionCoinsCost,
  disabled,
  onButtonClick,
}: {
  streakCount: number
  currencyType: CurrencyType
  extensionPiggiesCost: number
  extensionCoinsCost: number
  disabled: boolean
  onButtonClick?: () => void
}) => {
  const [isProcessing, setIsProcessing] = useState(false)

  const isCurrencyPig = useMemo(() => currencyType === CurrencyType.PIGGY_BANKS, [currencyType])
  const formattedCurrency = useMemo(
    () => formatNumber(isCurrencyPig ? extensionPiggiesCost : extensionCoinsCost),
    [isCurrencyPig, extensionPiggiesCost, extensionCoinsCost]
  )

  const handleClick = () => {
    if (isProcessing) {
      return
    }

    setIsProcessing(true)
    onButtonClick?.()

    setTimeout(() => setIsProcessing(false), 2000)
  }

  return (
    <div
      className={`dsrb_main-container ${(disabled || isProcessing) && 'disabled'}`}
      onClick={() => !disabled && handleClick()}
    >
      <div className="fire-icon">
        <FireIcon type="enabled" width={60} value={streakCount} />
      </div>
      <div className="currency">
        <span>Cost: {formattedCurrency}</span>
        <img src={isCurrencyPig ? '/images/pig.png' : '/images/coin.png'} alt="currency" />
      </div>
    </div>
  )
}

export default DailyStreakReviveButton
