import React, { useContext, useEffect, useMemo, useState } from 'react'
import { DateTime, Duration } from 'luxon'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import toast from 'react-hot-toast'

//assets
import Timer from 'assets/offers/timer.png'
//components
import ValueFrame from 'components/valueFrame'
import BottomButton from 'components/common/BottomButton'
import ProgressBar from 'components/ProgressBar'
import ButtonWithFloatingText from 'components/ButtonWithFloatingText'
//constants
import { ANIMATION_LEVELS, QUEST_TYPE } from 'constants/index'
//contexts
import AuthContext from 'context/AuthContext'
//services
import { claimQuestReward } from 'services/questsService'
import { getUserById } from 'services/userService'
//types
import { QuestListItemProps, User } from 'types'
//utils
import { getAnimationLevel, handleHeaderCounter } from 'utils/animations'
import { formatTaskTimeLeft, getTimeDifference } from 'utils/genericOffer'
import { getProgressText } from 'utils/quest'
import { checkDateIsLessToday, createVirtualCurrencyImagesArrays, formatDate } from 'utils/utils'

const QuestsListItem = ({
  quest,
  type,
  onClaim,
  setShowBackgroundPigsAnimation,
}: QuestListItemProps) => {
  const { user, updateUserData } = useContext(AuthContext)
  const [pendingUpdate, setPendingUpdate] = useState<User | null>(null)

  const [coinsArray, setCoinsArray] = useState([])
  const [pigsArray, setPigsArray] = useState([])

  const [time, setTime] = useState<Duration>()
  const [isClaiming, setIsClaiming] = useState(false)
  const [isSwiping, setIsSwiping] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const [shouldBeRendered, setShouldBeRendered] = useState(true)

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const progressBar = useMemo(() => getProgressText(quest), [quest])
  const isClaimable = useMemo(() => type === QUEST_TYPE.COMPLETED, [type])

  useEffect(() => {
    if (type === QUEST_TYPE.WEEKLY || !quest.expiredAt || isClaimable) {
      return
    }

    const luxonQuestEndTime = DateTime.fromJSDate(new Date(quest.expiredAt._seconds * 1000))
    const luxonCurrentTime = DateTime.now()
    const diff = getTimeDifference(luxonQuestEndTime, luxonCurrentTime)
    setTime(diff)
    const interval = setInterval(() => {
      const luxonCurrentTime = DateTime.now()
      const diff = getTimeDifference(luxonQuestEndTime, luxonCurrentTime)

      if (diff.valueOf() <= 0) {
        clearInterval(interval)
        return queryClient.invalidateQueries('quests')
      }
      setTime(diff)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [type, quest, isClaimable])

  useEffect(() => {
    if (pendingUpdate && !shouldBeRendered) {
      updateUserData(pendingUpdate)
      setPendingUpdate(null)
    }

    return () => {
      if (pendingUpdate) {
        updateUserData(pendingUpdate)
      }
    }
  }, [pendingUpdate, shouldBeRendered])

  const handleClaim = (sourceDiv: EventTarget | null) => {
    const animationLevel = getAnimationLevel(quest.piggyReward)

    setIsClaiming(true)

    createVirtualCurrencyImagesArrays(
      sourceDiv,
      quest.coinsReward,
      quest.piggyReward,
      setCoinsArray,
      setPigsArray,
      0.7,
      animationLevel
    )

    const displayVFXBehindCurrencyIcon = animationLevel >= ANIMATION_LEVELS.LEVEL_2
    setTimeout(() => {
      handleHeaderCounter(false, quest.coinsReward, displayVFXBehindCurrencyIcon)
      handleHeaderCounter(true, quest.piggyReward, displayVFXBehindCurrencyIcon)
    }, 700)

    if (animationLevel >= ANIMATION_LEVELS.LEVEL_3) {
      setShowBackgroundPigsAnimation(true)
    }

    claimQuestReward(quest.id)
      .then((res) => setPendingUpdate(res.data.updatedUser))
      .catch((error) => {
        if (
          error?.response?.data?.message ===
          'Error with quest claim transaction: QUEST_ERROR: Reward for this quest is already claimed!'
        ) {
          toast.error('Reward for this quest is already claimed!')
        }
        getUserById(user?.id)
          .then((res) => updateUserData(res.data))
          .catch(() => toast.error('Unable to fetch user details.'))
      })

    setTimeout(() => setIsSwiping(true), 1250)
    setTimeout(() => {
      setIsHidden(true)
      onClaim && onClaim()
      setIsClaiming(false)
      setShouldBeRendered(false)
    }, 2250)
  }

  const handleNavigate = () => {
    navigate(quest.CTA.link)
  }

  if (!shouldBeRendered) {
    return null
  }

  return (
    <div className="questListItemContainer">
      <Typography variant="body2">{coinsArray}</Typography>
      <Typography variant="body2">{pigsArray}</Typography>
      {time && (
        <div className="offer-limited-time">
          <img src={Timer} />
          Limited time: <strong>{formatTaskTimeLeft(time)}</strong>
        </div>
      )}
      <div
        className={`questsListItemInner ${time ? 'showTime' : ''} ${
          isSwiping ? 'alertSwipe' : ''
        } ${isHidden ? 'hidden' : ''}`}
      >
        <div className="questsListItemHeader">
          <Typography variant="h4" sx={styles.title}>
            {quest.title}
          </Typography>
          {isClaimable && (
            <Typography variant="small" className="questsListItemCompletedDate">
              {formatDate(quest.completedAt)}
            </Typography>
          )}
        </div>
        <div className="questsListItem">
          <img className="questsListItemImage" src={quest.imageUrl} />
          <div className="questsListItemContent">
            <Typography variant="h4" sx={styles.text}>
              {quest.subtitle}
            </Typography>
            <div className="questsListItemValues">
              {quest.piggyReward > 0 && <ValueFrame value={quest.piggyReward} isPig={true} />}
              {quest.coinsReward > 0 && <ValueFrame value={quest.coinsReward} isModal={false} />}
            </div>
          </div>
        </div>
        <div className="questsListItemContent">
          {quest.progressMeter && (
            <div className="questsListItemProgress">
              <ProgressBar
                fillerType={
                  quest.completedAt && checkDateIsLessToday(quest.completedAt)
                    ? 'completed'
                    : 'gold'
                }
                completed={progressBar?.progress}
                style={styles.progress}
              />
              <Typography variant="small" sx={styles.progressValue}>
                {progressBar?.text}
              </Typography>
            </div>
          )}
          {isClaimable && (
            <ButtonWithFloatingText
              customStyle={styles.claimButton}
              piggyBanks={quest.piggyReward}
              coins={quest.coinsReward}
              onClick={(event: Event) => handleClaim(event.currentTarget)}
              disabled={isClaiming}
            >
              Claim!
            </ButtonWithFloatingText>
          )}
          {quest.CTA && !isClaimable && (
            <BottomButton style={styles.button} handleClick={handleNavigate}>
              {quest.CTA.text}
            </BottomButton>
          )}
        </div>
      </div>
    </div>
  )
}

const styles = {
  title: {
    fontWeight: 700,
    color: '#1F1F1F',
  },
  text: {
    fontWeight: 400,
    color: '#636874',
  },
  claimButton: {
    padding: '10px 0',
    background:
      'linear-gradient(168.92deg, #ACFFA5 6.85%, #04DF90 90.71%) padding-box, linear-gradient(93.2deg, #04DF90 16.28%, #ACFFA5 92.47%) border-box',
    '&:hover': {
      background:
        'linear-gradient(270deg, #a4fea4 4.52%, #12e292 92.66%) padding-box, linear-gradient(93.2deg, #04DF90 16.28%, #ACFFA5 92.47%) border-box',
      boxShadow: 'none',
    },
  },
  button: {
    padding: '10px 0',
  },
  progress: {
    border: '1px solid #FFC11F66',
    boxShadow: '2px 4px 12px 0px #FF962B1F',
    padding: '1px',
  },
  progressValue: {
    fontWeight: 700,
    color: '#3C4249',
    span: {
      fontWeight: 400,
    },
  },
  piggyValue: {
    background:
      'linear-gradient(#fff9f3,#fff9f3) padding-box,linear-gradient(#ff6492, #FF6492) border-box',
  },
  coinsValue: {
    background:
      'linear-gradient(#fff9f3,#fff9f3) padding-box,linear-gradient(#ffa70b, #ffa70b) border-box',
  },
  lockedText: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontWeight: 400,
    svg: {
      path: {
        fill: '#FFFFFF',
      },
    },
  },
}

export default QuestsListItem
