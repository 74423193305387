import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'

//components
import BottomButton from '../common/BottomButton'
//styles
import './index.css'
//types
import { ClaimAllWithFloatingTextProps } from 'types'
//utils
import { formatNumber } from 'utils/utils'

const ClaimAllWithFloatingText = ({
  piggyBanks,
  coins,
  children,
  customClassName,
  customStyle,
  onClick,
  disabled = false,
}: ClaimAllWithFloatingTextProps) => {
  const [showText, setShowText] = useState(false)

  useEffect(() => {
    if (showText) {
      const timer = setTimeout(() => setShowText(false), 2000)
      return () => clearTimeout(timer)
    }
  }, [showText])

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {showText && (
        <Typography variant="h2" className="floatingText">
          {piggyBanks > 0 && (
            <span className="textPig">
              +{formatNumber(piggyBanks)} <img src={`/images/pig.png`} alt="pig" />
            </span>
          )}{' '}
          {coins > 0 && (
            <span className="textCoin">
              +{formatNumber(coins)} <img src={`/images/coin.png`} alt="coin" />
            </span>
          )}
        </Typography>
      )}

      <BottomButton
        style={customStyle}
        className={customClassName}
        handleClick={(event: Event) => {
          onClick(event)
          setShowText(true)
        }}
        disabled={disabled}
      >
        {children}
      </BottomButton>
    </div>
  )
}

export default ClaimAllWithFloatingText
