import { useContext } from 'react'

//contexts
import { DeltaTimeContext } from 'context/DeltaTimeContext'

const useDeltaTime = () => {
  const context = useContext(DeltaTimeContext)

  if (!context) {
    throw new Error('useDeltaTime must be used within an DeltaTimeProvider')
  }

  return context
}

export default useDeltaTime
