import { HTMLAttributes, useMemo } from 'react'

//components
import ProgressBar from 'components/ProgressBar'
import FireIcon from '../FireIcon'
import RewardPoints from 'components/RewardPoints'
//styles
import './index.css'
//utils
import {
  EACH_STREAK_SET_COUNT,
  STREAK_BREAK_POINTS,
  getMinDivisibleByNumber,
} from 'utils/dailyStreak'
import {
  DailyStreakConfig,
  getDailyRewardBonusPiggyBanks,
} from '@playback-rewards/shared-libraries'

const DailyStreakProgressBar = ({
  streakCount,
  streakConfig,
  ...props
}: {
  streakCount: number
  streakConfig: DailyStreakConfig
} & HTMLAttributes<HTMLDivElement>) => {
  const streakValues = useMemo(() => {
    if (streakCount <= 0) {
      return { baseValue: 0, dayCount: 0 }
    }
    const baseValue = getMinDivisibleByNumber(streakCount, EACH_STREAK_SET_COUNT)
    const dayCount = streakCount - baseValue
    return { baseValue, dayCount }
  }, [streakCount, getMinDivisibleByNumber])
  const bonusPoints = useMemo(
    () =>
      STREAK_BREAK_POINTS.map((p) => {
        return getDailyRewardBonusPiggyBanks(streakValues.baseValue + p, streakConfig)
      }),
    [streakCount, getDailyRewardBonusPiggyBanks, streakConfig]
  )
  const progress = useMemo(
    () => Math.min(Math.round((streakValues.dayCount / EACH_STREAK_SET_COUNT) * 100), 100),
    [streakValues.dayCount]
  )
  const isFirstSetCompleted = useMemo(
    () => streakCount > EACH_STREAK_SET_COUNT,
    [streakValues.baseValue]
  )

  return (
    <div {...props}>
      <div
        className="dspb_progress-bar-container"
        style={{ marginLeft: isFirstSetCompleted ? -10 : 0 }}
      >
        {isFirstSetCompleted && (
          <ProgressBar
            fillerType="streak-transparent"
            completed={100}
            style={{
              ...styles.progressBar,
              width: 30,
              backgroundColor: '#0000',
            }}
          />
        )}
        <div
          style={{
            flex: 1,
            transform: isFirstSetCompleted ? 'translateX(-6px)' : 'translateX(0px)',
          }}
        >
          <ProgressBar
            fillerType="streak"
            completed={progress}
            style={{
              backgroundColor: '#CBD5E1',
              ...styles.progressBar,
              flex: 1,
            }}
          />

          <div className="fire-icons">
            {STREAK_BREAK_POINTS.map((p, index) => {
              const value = streakValues.baseValue + p
              const shouldShowFirstBonus = index === 0 && bonusPoints[0] <= 0
              return (
                <div key={`days_${p}_${index}`} className="fire-item">
                  <FireIcon
                    type={streakCount >= value ? 'enabled' : 'disabled'}
                    width={26}
                    value={value}
                    avoidBoldText
                  />
                  <div
                    key={`bonus_${p}_${index}`}
                    className="reward"
                    style={{
                      visibility: shouldShowFirstBonus ? 'hidden' : 'visible',
                    }}
                  >
                    <RewardPoints
                      type="big"
                      value={bonusPoints[index]}
                      isPig={true}
                      showBG
                      avoidResponsiveness
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  progressBar: {
    border: 'none',
    boxShadow: 'none',
    height: 4,
    padding: 0,
    transform: 'translateY(22px)',
  },
}

export default DailyStreakProgressBar
