import { gsap } from 'gsap'
import { MotionPathPlugin, CustomEase } from 'gsap/all'

import {
  ANIMATION_LEVELS,
  DEFAULT_COUNTER_ANIMATION_DIVIDER,
  VIRTUAL_CURRENCY_TYPE,
} from '../constants'
import { convertTo_K_M_B_Format, getHeaderImageDiv, parseFormattedNumber } from './utils'
import 'App.css'

gsap.registerPlugin(MotionPathPlugin, CustomEase)

CustomEase.create(
  'easePath',
  'M0,0 C0.12,0.08 0.349,0.467 0.45,0.527 0.52,0.567 0.752,0.638 0.792,0.678 0.882,0.848 1,1.018 1,1.018'
)

export const textFade = (ref) => {
  gsap.to(ref.current, {
    duration: 0.5,
    y: -60,
    zIndex: 1001,
  })
}

export const counter = async (
  tag,
  minValue,
  maxValue,
  step = null,
  isPig = false,
  func = null,
  textRef = null,
  divider = DEFAULT_COUNTER_ANIMATION_DIVIDER
) => {
  const fracStep = Math.abs(maxValue - minValue) / divider
  const stepValue = step ? step : fracStep

  const isIncreasing = minValue < maxValue
  const nextValue = isIncreasing ? minValue + stepValue : minValue - stepValue

  if (textRef && !step) {
    textFade(textRef, (maxValue - minValue) / (stepValue * 1000))
  }

  if ((isIncreasing && nextValue < maxValue) || (!isIncreasing && nextValue > maxValue)) {
    tag.innerHTML = convertTo_K_M_B_Format(Math.floor(nextValue))
    setTimeout(() => counter(tag, nextValue, maxValue, stepValue, isPig, func), 10)
    return
  }

  tag.innerHTML = convertTo_K_M_B_Format(maxValue)
  func && func()
}

export const moveToHead = (
  ref,
  headerTag,
  buttonDivRect,
  coordinates,
  duration = 1,
  virtualCurrencyType,
  isSingleVirtualCurrencyClaim
) => {
  const headerImage = headerTag?.getElementsByTagName('img')[0]
  const headerImageRect = headerImage?.getBoundingClientRect()

  const DISTANCE_BETWEEN_ICONS_START_POINT = 50
  const iconOffset =
    virtualCurrencyType === VIRTUAL_CURRENCY_TYPE.COIN ? DISTANCE_BETWEEN_ICONS_START_POINT : 0

  const topScreenCoords = { x: window.innerWidth / 1.5 + iconOffset, y: -40 }

  let startX = buttonDivRect.width / 2
  if (!isSingleVirtualCurrencyClaim) {
    const offset = DISTANCE_BETWEEN_ICONS_START_POINT / 2
    startX += virtualCurrencyType === VIRTUAL_CURRENCY_TYPE.COIN ? offset : -offset
  }

  const start = { x: startX, y: 0 }
  gsap.set(ref.current, start)

  gsap.to(ref.current, {
    duration,
    ease: 'easePath',
    motionPath: {
      path: () => {
        const scrollOffset = window.scrollY // Get the latest scroll position
        const dynamicEnd = {
          x: (headerImageRect?.left || topScreenCoords.x) - coordinates.x,
          y: (headerImageRect?.top || topScreenCoords.y) - coordinates.y + scrollOffset,
        }

        // Recalculate control point
        const calculatedMidX = (dynamicEnd.y - start.y) / 1.8 + start.x
        const midX = Math.max(calculatedMidX, -buttonDivRect.left + iconOffset)
        const midY = start.y + (dynamicEnd.y - start.y) * 0.7
        const control = { x: midX, y: midY }

        return [start, control, dynamicEnd] // Recalculate path dynamically
      },
      autoRotate: false,
      curviness: 1.35,
    },
    height: headerImageRect && headerImageRect.height,
    zIndex: 9999,
    onUpdate: function () {
      if (this.progress() >= 0.95) {
        gsap.set(ref.current, { opacity: 0 })
      }
    },
    onComplete: function () {
      if (headerTag) {
        headerImage.classList.add('grow')
        setTimeout(() => {
          headerImage.classList.remove('grow')
        }, 10)
      }
    },
  })
}

const handleCurrencyBarVFX = (headerDiv) => {
  const imgElement = headerDiv.getElementsByTagName('img')[0]
  if (!imgElement) {
    return
  }

  const gifImg = document.createElement('img')
  gifImg.src = '/images/currencyBarVFX.gif'

  headerDiv.style.position = 'relative'

  gifImg.style.position = 'absolute'
  gifImg.style.top = '50%'
  gifImg.style.left = '62%'
  gifImg.style.transform = 'translate(-30%, -50%)'
  gifImg.style.opacity = '0.7'
  gifImg.style.pointerEvents = 'none'
  gifImg.style.zIndex = '1002'
  gifImg.style.transition = 'opacity 1s ease-in-out'

  headerDiv.appendChild(gifImg)

  setTimeout(() => {
    gifImg.style.opacity = '0'
    setTimeout(() => {
      if (headerDiv.contains(gifImg)) {
        headerDiv.removeChild(gifImg)
      }
    }, 1000)
  }, 2500)
}

export const getCoordinates = (ref) => {
  const coordinates = ref.current?.getBoundingClientRect() || ref.getBoundingClientRect()
  return {
    x: coordinates?.left,
    y: coordinates?.top + window.scrollY,
  }
}

export const getImagePosition = (coordinates, alignRight) => {
  if (alignRight) {
    return { top: coordinates.y, right: window.innerWidth - coordinates.x }
  }
  return { top: coordinates.y, left: coordinates.x }
}

export const handleHeaderCounter = (
  isPig = false,
  addedValue = 0,
  displayVFXBehindCurrencyIcon = false,
  divider = DEFAULT_COUNTER_ANIMATION_DIVIDER,
  func
) => {
  const headerDiv = getHeaderImageDiv(isPig)
  if (headerDiv) {
    if (displayVFXBehindCurrencyIcon && addedValue > 0) {
      handleCurrencyBarVFX(headerDiv)
    }

    const text = headerDiv.getElementsByTagName('h4')[0]
    const value = parseFormattedNumber(text.textContent)
    counter(text, value, value + addedValue, null, isPig, func, null, divider)
  }
}

export const handleHeaderDecrementCounter = (
  isPig,
  currentValue,
  targetValue,
  divider = DEFAULT_COUNTER_ANIMATION_DIVIDER
) => {
  const headerDiv = getHeaderImageDiv(isPig)
  if (headerDiv) {
    const text = headerDiv.getElementsByTagName('h4')[0]
    counter(text, currentValue, targetValue, null, isPig, null, null, divider)
  }
}

export const getAnimationLevel = (aggregatedPiggyBanks) => {
  if (aggregatedPiggyBanks <= 50) {
    return ANIMATION_LEVELS.LEVEL_1
  } else if (aggregatedPiggyBanks < 200) {
    return ANIMATION_LEVELS.LEVEL_2
  }
  return ANIMATION_LEVELS.LEVEL_3
}

export const getAnimationIconsCap = (animationLevel) => {
  switch (animationLevel) {
    case ANIMATION_LEVELS.LEVEL_1:
      return 15
    case ANIMATION_LEVELS.LEVEL_2:
      return 25
    case ANIMATION_LEVELS.LEVEL_3:
      return 40
  }
}
