import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'

//components
import ValueFrame from 'components/valueFrame'
//styles
import './index.css'
//types
import { RewardItemProps } from 'types'
//utils
import { CheckMark } from 'utils/icons'

const RewardItem = ({ reward, shouldBeHidden }: RewardItemProps) => {
  const [isSwiping, setIsSwiping] = useState(false)
  const [isHidden, setIsHidden] = useState(false)

  useEffect(() => {
    if (shouldBeHidden) {
      setTimeout(() => {
        setIsSwiping(true)
        setTimeout(() => setIsHidden(true), 1000)
      }, 100)
    }
  }, [shouldBeHidden])

  return (
    <Box className={`rewardItem ${isSwiping ? 'itemSwipe' : ''} ${isHidden ? 'hidden' : ''}`}>
      <div className="rewardItemContent">
        <img src={reward.iconUrl} className="rewardItemIcon" />
        <div className="rewardItemInfo">
          <Typography variant="body2">{reward.title}</Typography>
          {reward.subtitle && (
            <Typography variant="body2" className="subtitle">
              {reward.subtitle}
            </Typography>
          )}
          <Typography variant="body2" className="complete">
            <CheckMark />
            Completed
          </Typography>
        </div>
      </div>
      <div className="rewardItemRewardSection">
        {reward.earnedPiggy > 0 && (
          <ValueFrame value={reward.earnedPiggy} isPig={true} isModal={false} />
        )}
        {reward.earnedCoins > 0 && (
          <ValueFrame value={reward.earnedCoins} isPig={false} isModal={false} />
        )}
      </div>
    </Box>
  )
}

export default RewardItem
