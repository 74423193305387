import { DEVICE_INFO } from './deviceInfo'
import { GEO_DATA } from './geoData'
import { GIFT_CARD } from './giftCard'
import { GIFT_CARD_ORDERS } from './giftCardOrders'
import { DAILY_STREAK } from './dailyStreak'
import { DATE_TIME } from './dateTime'
import { REFERRAL } from './referral'

const QueryKeys = {
  ...DEVICE_INFO,
  ...GEO_DATA,
  ...GIFT_CARD,
  ...GIFT_CARD_ORDERS,
  ...DAILY_STREAK,
  ...DATE_TIME,
  ...REFERRAL,
}

export default QueryKeys
