import React from 'react'
import { Box } from '@mui/material'

// styles
import './index.css'

// components
import BottomButton from '../common/BottomButton'

// types
import { PaginateProps } from '../../types'

const Paginator = ({ hasNextPage, loadMore }: PaginateProps) => {
  return (
    <Box className="paginatorContainer">
      {hasNextPage && (
        <BottomButton className="showMoreButton" handleClick={loadMore}>
          Show More
        </BottomButton>
      )}
    </Box>
  )
}

export default Paginator
