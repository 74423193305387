import React, { useEffect, useState } from 'react'
import BottomButton from '../../../../../components/common/BottomButton'
import { CLAIM_SPECIAL_REWARD_REASON } from '../../../../../constants'

export const Button = ({
  isActivated,
  reward,
  handleCreatePlayGameReward,
  handleClaimSpecialReward,
  handleTryGame,
  validating,
  startDate,
  handlePlayGame,
  isTry = false,
  claimed = false,
  isDisabled = false,
}) => {
  const [disabled, setDisabled] = useState(isDisabled)

  useEffect(() => setDisabled(isDisabled), [isDisabled])

  const handleClick = (func) => {
    setDisabled(true)
    func()
  }

  if (validating) {
    return (
      <BottomButton style={styles.button} handleClick={() => handlePlayGame()}>
        Validating...
      </BottomButton>
    )
  }

  if (!isActivated && isTry) {
    return (
      <BottomButton style={styles.button} handleClick={() => handleClick(handleTryGame)}>
        Try game
      </BottomButton>
    )
  }

  if (isActivated && !isTry && startDate?._seconds <= new Date().getTime() / 1000 && !claimed) {
    return (
      <BottomButton
        style={styles.button}
        disabled={disabled}
        handleClick={() =>
          handleClick(() => {
            handleCreatePlayGameReward(reward.day)
            handlePlayGame()
          })
        }
      >
        Play
      </BottomButton>
    )
  }

  if (!isTry && (!startDate || startDate?._seconds > new Date().getTime() / 1000)) {
    return null
  }

  return (
    <BottomButton
      style={styles.button}
      disabled={disabled}
      handleClick={(event) =>
        handleClick(() =>
          handleClaimSpecialReward(
            event.currentTarget,
            {
              earnedCoins: reward.rewards.coins,
              earnedPiggy: reward.rewards.piggyBanks,
              day: reward.day,
            },
            isTry ? CLAIM_SPECIAL_REWARD_REASON.INSTALL_GAME : CLAIM_SPECIAL_REWARD_REASON.PLAY_GAME
          )
        )
      }
      autoBlock={true}
    >
      Claim!
    </BottomButton>
  )
}

export const TaskActionButton = ({
  text,
  handleClick,
  isDisabled = false,
  disableOnClick = false,
}) => {
  const [disabled, setDisabled] = useState(isDisabled)

  const handleClickHandler = (event) => {
    if (disableOnClick) {
      setDisabled(true)
    }
    handleClick(event)
  }

  return (
    <BottomButton style={styles.button} disabled={disabled} handleClick={handleClickHandler}>
      {text}
    </BottomButton>
  )
}

const styles = {
  button: {
    padding: '6px 14px',
    width: 'max-content',
    fontWeight: 700,
    fontFamily: 'var(--poppins-font)',
  },
}

export default Button
