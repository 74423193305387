import React from 'react'
import Modal from '@mui/material/Modal'
import { DailyStreakConfig, DailyStreakStatus } from '@playback-rewards/shared-libraries'

//assets
import Timer from 'assets/offers/timer.png'
//components
import FireIcon from '../FireIcon'
import DailyStreakProgressBar from '../DailyStreakProgressBar'
import BottomButton from 'components/common/BottomButton'
//styles
import './index.css'

const DailyStreakInfo = ({
  open,
  streakStatus,
  streakCount,
  streakConfig,
  streakReadyIn,
  onClose,
}: {
  open: boolean
  streakStatus: DailyStreakStatus
  streakCount: number
  streakConfig: DailyStreakConfig
  streakReadyIn: string
  onClose: () => void
}) => {
  return (
    <Modal open={open}>
      <div className="dsi_main-container">
        <img src="/images/background.png" alt="bg" className="background" />
        <div className="content-container">
          <div className="content">
            <FireIcon type="enabled" width={120} value={streakCount} />
            <p className="heading">day streak!</p>
            {streakStatus === DailyStreakStatus.CLAIMED && (
              <p className="info" style={{ padding: '0px 16px' }}>
                You’ve already extended your streak for the day.
              </p>
            )}
            <p className="sub-title">
              Keep your streak going tomorrow to get the next level bonus!
            </p>
            <DailyStreakProgressBar
              className="progress-bar"
              streakCount={streakCount}
              streakConfig={streakConfig}
            />
            <p className="info" style={{ padding: '0px 24px', marginTop: 24 }}>
              Your streak will reset if you don’t extend it everyday.
            </p>
            {streakStatus === DailyStreakStatus.CLAIMED && (
              <p className="info" style={{ marginTop: 24 }}>
                Extend your daily streak in:{' '}
                <span className="time-to-reset">
                  <img src={Timer} />
                  {' ' + streakReadyIn}
                </span>
              </p>
            )}
          </div>
          <BottomButton className="actionButton" handleClick={onClose}>
            Continue
          </BottomButton>
        </div>
      </div>
    </Modal>
  )
}

export default DailyStreakInfo
