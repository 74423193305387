import { createContext, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { FTimestampFunc } from '@playback-rewards/shared-libraries'

//constants
import QueryKeys from 'constants/queryKeys'
//services
import { getUTCTime } from 'services/dateTime'

export const DeltaTimeContext = createContext<{ delta: number | null }>({
  delta: null,
})

const DeltaTimeProvider = ({ children }: { children: React.ReactNode }) => {
  const { data } = useQuery(QueryKeys.GET_UTC_TIME, getUTCTime, {
    retry: 1,
    staleTime: 60 * 1000, // Increase cache time to reduce redundant API calls
  })

  const [delta, setDelta] = useState<number | null>(null)

  useEffect(() => {
    if (!data?.data) {
      return
    }

    try {
      const localTimeInMillis = Date.now()
      const serverTimeInMillis = FTimestampFunc.toMillis(data.data)
      const delta = serverTimeInMillis - localTimeInMillis
      setDelta(delta)
    } catch (error) {
      console.error('Error syncing time:', error)
    }
  }, [data?.data])

  return <DeltaTimeContext.Provider value={{ delta }}>{children}</DeltaTimeContext.Provider>
}

export default DeltaTimeProvider
