import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import toast from 'react-hot-toast'
import { GenericReferralConfig } from '@playback-rewards/shared-libraries'

//constants
import QueryKeys from 'constants/queryKeys'
//services
import { getReferralConfig } from 'services/referalService'

const useQueryGetReferralConfig = () => {
  const { data, isLoading } = useQuery<AxiosResponse<GenericReferralConfig, Error>>(
    QueryKeys.GET_REFERRAL_CONFIG,
    getReferralConfig,
    {
      onError: (error) => {
        toast.error(
          ((error as AxiosError)?.response?.data as Error).message || 'Something went wrong!'
        )
      },
    }
  )

  return {
    isLoadingReferralConfig: isLoading,
    referralConfigData: data?.data,
  }
}

export default useQueryGetReferralConfig
