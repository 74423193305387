import React, { useContext, useMemo, useState } from 'react'

//components
import RewardPoints from 'components/RewardPoints'
import ReferralItem from 'pages/Friends/ReferralItem'
//constants
import { REFERRAL_ITEM_COUNT_IN_UNEXPANDED_STATE } from 'constants/index'
//contexts
import AuthContext from 'context/AuthContext'
//types
import { ReferralListProps } from 'types'

const ReferralList = ({ state, referrals, referralConfig }: ReferralListProps) => {
  const { user } = useContext(AuthContext)
  const [isExpanded, setIsExpanded] = useState(false)
  const title = useMemo(() => (state === 'IN-PROGRESS' ? 'In Progress:' : 'Completed:'), [state])
  const refereesArray = useMemo(() => {
    if (isExpanded) {
      return referrals
    }
    return referrals.slice(0, REFERRAL_ITEM_COUNT_IN_UNEXPANDED_STATE)
  }, [isExpanded, referrals])
  const subTitle = useMemo(
    () => (state === 'IN-PROGRESS' ? 'Potential earnings:' : 'Rewards earned:'),
    [state]
  )
  const earnnedPigs: number = useMemo(
    () =>
      referrals.reduce((sum, data) => {
        if (user?.id === data.referrer.userId) {
          return sum + data.referrer.bonusPiggyBanks
        }
        return sum + data.referee.bonusPiggyBanks
      }, 0),
    [referrals, user?.id]
  )
  const earnnedCoins: number = useMemo(
    () =>
      referrals.reduce((sum, data) => {
        if (user?.id === data.referrer.userId) {
          return sum + data.referrer.bonusCoins
        }
        return sum + data.referee.bonusCoins
      }, 0),
    [referrals, user?.id]
  )

  return (
    <>
      <div className="state">
        <p className="text">
          {title} {referrals.length}
        </p>
        <div className="earnings">
          <p className="text">{subTitle}</p>
          <RewardPoints type="referral" value={earnnedPigs} isPig avoidResponsiveness />
          {earnnedCoins > 0 && (
            <RewardPoints type="referral" value={earnnedCoins} isPig={false} avoidResponsiveness />
          )}
        </div>
      </div>
      {refereesArray.map((referee, index) => (
        <ReferralItem
          key={`referee-${referee.referee.userId}-${index}`}
          data={referee}
          referralConfig={referralConfig}
        />
      ))}
      {referrals.length > REFERRAL_ITEM_COUNT_IN_UNEXPANDED_STATE && (
        <p onClick={() => setIsExpanded(!isExpanded)} className="show-all">
          {isExpanded ? 'Show less' : 'Show all'}
        </p>
      )}
    </>
  )
}

export default ReferralList
