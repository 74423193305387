import { AxiosResponse } from 'axios'
import { CurrencyType, DailyStreakConfig } from '@playback-rewards/shared-libraries'

import axiosWebInstance from 'axiosWebInstance'

export const getStreakConfig = (): Promise<AxiosResponse<DailyStreakConfig, Error>> =>
  axiosWebInstance.get('/api/v1/daily-streak/config')

export const dailyStreakAdWatched = (): Promise<AxiosResponse<object, Error>> =>
  axiosWebInstance.post('/api/v1/daily-streak/ad-watched')

export const extendDailyStreak = (
  currencyType: CurrencyType
): Promise<AxiosResponse<string, Error>> =>
  axiosWebInstance.post(`/api/v1/daily-streak/extension?currency=${currencyType}`)

export const resetDailyStreak = (): Promise<AxiosResponse<string, Error>> =>
  axiosWebInstance.post('/api/v1/daily-streak/reset')
