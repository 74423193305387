import React, { useContext, useState } from 'react'
import { Typography } from '@mui/material'

//contexts
import AuthContext from 'context/AuthContext'
//components
import Modal from './Modal'
import OfferAlert from 'components/offerAlert'
//constants
import { ANIMATION_LEVELS } from 'constants/index'
//types
import { PiggyBankChestProps, PiggyChestReward } from 'types'
//utils
import { getAnimationLevel, handleHeaderCounter } from 'utils/animations'
import { createVirtualCurrencyImagesArrays } from 'utils/utils'

const PiggyBankChest = ({ config, setShowBackgroundPigsAnimation }: PiggyBankChestProps) => {
  const { user, updateUserData } = useContext(AuthContext)

  const [updatedUser, setUpdatedUser] = useState(user)

  const [pigsArray, setPigsArray] = useState([])
  const [modal, setModal] = useState(false)
  const [swiping, setSwiping] = useState(false)
  const [hidden, setHidden] = useState(false)

  const handleShowPiggyBankChestModal = () => setModal(true)

  const hideModal = () => setModal(false)

  const handleClaim = async (sourceDiv: Element | null, chestReward: PiggyChestReward) => {
    const animationLevel = getAnimationLevel(chestReward.piggyBanks)

    createVirtualCurrencyImagesArrays(
      sourceDiv,
      0,
      chestReward.piggyBanks,
      null,
      setPigsArray,
      0.7,
      animationLevel
    )

    const displayVFXBehindCurrencyIcon = animationLevel >= ANIMATION_LEVELS.LEVEL_2
    const dividerForCounter = chestReward.piggyBanks < 20 ? 50 : 100
    setTimeout(() => {
      handleHeaderCounter(
        true,
        chestReward.piggyBanks,
        displayVFXBehindCurrencyIcon,
        dividerForCounter,
        () => {
          if (updatedUser) {
            updateUserData(updatedUser)
          }
        }
      )
    }, 700)

    setTimeout(() => setSwiping(true), 1000)
    setTimeout(() => setHidden(true), 2000)
  }

  return (
    <>
      <Modal
        showModal={modal}
        hideModal={hideModal}
        config={config}
        handleClaim={handleClaim}
        setUpdatedUser={setUpdatedUser}
        setShowBackgroundPigsAnimation={setShowBackgroundPigsAnimation}
      />
      <Typography variant="body2">{pigsArray}</Typography>
      <OfferAlert
        icon={<img src="/images/pigChestClosed.png" />}
        customClassName={`${swiping ? 'alertSwipe' : ''} ${hidden ? 'hidden' : ''}`}
        title={'Open a Piggy Bank Chest'}
        handleClick={handleShowPiggyBankChestModal}
        buttonText="Open"
      >
        Win up to 10,000 Piggy Banks!
      </OfferAlert>
    </>
  )
}

export default PiggyBankChest
