import React from 'react'
import { Box, DialogContent } from '@mui/material'
// components
import Modal from '../../../../components/modal'
import BottomButton from '../../../../components/common/BottomButton'
// types
import { InfoFeeModalProps } from 'types'
// styles
import './index.css'

const InfoFeeModal = ({
  showModal,
  toggleModal,
  giftCardName,
  fee,
  minFeeValue,
}: InfoFeeModalProps) => {
  return (
    <Modal
      showModal={showModal}
      hideModal={toggleModal}
      closeButton={true}
      title={`${giftCardName} Fees`}
      isCustomText={true}
      titleVariant="title"
      text={
        <DialogContent>
          <Box className="modal-text">{giftCardName} charges us the greater of:</Box>
          <Box className="modal-text">
            ${minFeeValue} per transaction or {fee}% of the value of the transaction.
          </Box>
          <Box className="modal-text">
            Due to this, {giftCardName} redemptions are slightly more expensive than other options.
          </Box>
          <BottomButton style={style.button} handleClick={toggleModal}>
            Okay
          </BottomButton>
        </DialogContent>
      }
    />
  )
}

const style = {
  button: {
    background: '#FB923C',
    color: 'white',
    '&:hover': {
      background: '#FB923C',
    },
  },
}
export default InfoFeeModal
