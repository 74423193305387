import React, { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'

//components
import Content from './Content'
import Layout from 'components/layout/Games'
import SlideLayout from 'components/layout/Games/Slide'
//contexts
import AuthContext from 'context/AuthContext'
import LoaderContext from 'context/LoaderContext'
//constants
import { LOCAL_STORAGE } from 'constants/index'
//services
import { getAllGiftCards } from 'services/giftCardsService'
//styles
import './index.css'
//utils
import { handleHeaderDecrementCounter } from 'utils/animations'
import { handleError } from 'utils/utils'

const PREV_ROUTES = ['/library', '/games', '/quests']

const GiftCardsList = () => {
  const { user } = useContext(AuthContext)
  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  const { data: giftCards, isFetching } = useQuery('giftCards', getAllGiftCards, {
    refetchOnWindowFocus: false,
    onError: handleError,
  })

  useEffect(() => {
    if (isFetching) {
      showLoader()
      return
    }
    hideLoader()

    const stringifiedPreviousBalance = localStorage.getItem(
      LOCAL_STORAGE.BALANCE_BEFORE_GIFT_CARD_REDEMPTION
    )
    if (stringifiedPreviousBalance) {
      const previousBalance = JSON.parse(stringifiedPreviousBalance)

      handleHeaderDecrementCounter(false, previousBalance.coins, user?.points)
      handleHeaderDecrementCounter(true, previousBalance.piggyBanks, user?.piggyBanks)
      localStorage.removeItem(LOCAL_STORAGE.BALANCE_BEFORE_GIFT_CARD_REDEMPTION)
    }
  }, [isFetching])

  return (
    <Layout isLoading={isLoading}>
      <SlideLayout prevRoutes={PREV_ROUTES} swipeIn={!isFetching} isSwiping={false}>
        <Content giftCards={giftCards} />
      </SlideLayout>
    </Layout>
  )
}

export default GiftCardsList
