import React, { useState, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { useQueryClient } from 'react-query'

import { MAIN_PAGE } from '../../../../../constants'
import { getProgressText } from '../../../../../utils/quest'
import ProgressBar from '../../../../../components/ProgressBar'
import { formatTaskTimeLeft, getTimeDifference } from '../../../../../utils/genericOffer'
import Timer from '../../../../../assets/offers/timer.png'
import BottomButton from '../../../../../components/common/BottomButton'
import ValueFrame from '../../../../../components/valueFrame'

import './index.css'

const QuestAlert = ({ quest }) => {
  const [time, setTime] = useState(null)

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const isQuestAlertCTAShown = useMemo(
    () => quest.isNotificationCTAShown && quest.CTA && quest.CTA.link !== MAIN_PAGE,
    [quest.isNotificationCTAShown, quest.CTA]
  )
  const progressBar = useMemo(() => getProgressText(quest), [quest])

  const buttonText = useMemo(
    () => (isQuestAlertCTAShown ? quest.CTA.text : null),
    [quest.isNotificationCTAShown, quest.CTA]
  )

  useEffect(() => {
    if (!quest.expiredAt) {
      return
    }

    const luxonQuestEndTime = DateTime.fromJSDate(new Date(quest.expiredAt._seconds * 1000))
    const luxonCurrentTime = DateTime.now()
    const diff = getTimeDifference(luxonQuestEndTime, luxonCurrentTime)
    setTime(diff)
    const interval = setInterval(() => {
      const luxonCurrentTime = DateTime.now()
      const diff = getTimeDifference(luxonQuestEndTime, luxonCurrentTime)

      if (diff.valueOf() <= 0) {
        clearInterval(interval)
        return queryClient.invalidateQueries('quests')
      }
      setTime(diff)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleAlertClick = () => {
    if (isQuestAlertCTAShown) {
      navigate(quest.CTA.link)
    }
  }

  return (
    <div className="gameDetailsOfferParentContainer">
      {time && (
        <div className="offer-limited-time">
          <img src={Timer} />
          Limited time: <strong>{formatTaskTimeLeft(time)}</strong>
        </div>
      )}
      <Alert severity="info" icon={false} className={`offerAlert ${time && 'showTime'}`}>
        <div className="offerAlertContent">
          <div className="questContainer">
            <div className="questImageTextContainer">
              <img className="questImage" src={quest.imageUrl} />
              <div className="textBlock">
                <Typography variant="h4" className="title">
                  {quest.title}
                </Typography>
                <Typography variant="body2" className="children">
                  {quest.subtitle}
                </Typography>
              </div>
            </div>
            <div className="questAlertReward">
              {quest.piggyReward > 0 && (
                <ValueFrame value={quest.piggyReward} isPig={true} isModal={false} />
              )}
              {quest.coinsReward > 0 && (
                <ValueFrame value={quest.coinsReward} isPig={false} isModal={false} />
              )}
            </div>
          </div>

          {progressBar && (
            <>
              <div className="questProgress">
                <ProgressBar
                  fillerType="gold"
                  completed={progressBar.progress}
                  style={styles.progress}
                />
                <Typography variant="body2" sx={styles.progressValue}>
                  {progressBar.text}
                </Typography>
              </div>
              {buttonText && (
                <BottomButton className="actionButton" handleClick={handleAlertClick}>
                  {buttonText}
                </BottomButton>
              )}
            </>
          )}
        </div>
      </Alert>
    </div>
  )
}

const styles = {
  progress: {
    border: '1px solid #FFC11F66',
    boxShadow: '2px 4px 12px 0px #FF962B1F',
    padding: '1px',
  },
  progressValue: {
    fontWeight: 400,
    color: '#3C4249',
  },
}

export default QuestAlert
