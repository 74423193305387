import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Container } from '@mui/material'
import { ref, onChildAdded } from 'firebase/database'
import { useNavigate } from 'react-router-dom'

import { captureEvent } from '@sentry/react'
import NativeAppContext from 'context/NativeApp/NativeAppContext'
import { checkDailyStreakVisibility } from 'utils/dailyStreak'
import { db } from '../../config/firebaseConfig'
import AuthContext from '../../context/AuthContext'
import LoaderContext from '../../context/LoaderContext'
import HistoryContext from '../../context/HistoryContext'
import Layout from '../../components/layout/Games'
import { getFeaturedGameOffers, getAllUnclaimedRewards } from '../../services/userRewardsService'
import { ERRORS, OFFER_TYPE, ONBOARDING_STEP, REACT_NATIVE_STATUS } from '../../constants'
import { handleError, sendRNMessage, showTOSPromptForUser } from '../../utils/utils'
import { getSuggestedQuest, refreshUserQuests } from '../../services/questsService'
import SlideLayout from '../../components/layout/Games/Slide'
import { getPiggyChestConfig } from '../../services/piggyBankChestService'
import useTOSPromptConfig from '../../hooks/useTOSPromptConfig'
import BackgroundPigsAnimation from '../../components/BackgroundPigsAnimation'
import Content from './Content'
import QuestAlert from './components/Alerts/Quest'
import PiggyBankChest from './components/Alerts/PiggyBankChest'
import RateAppAlert from './components/Alerts/RateApp/RateAppAlert'
import DailyStreakView from './components/DailyStreakView'
import AggregatedReward from './components/Alerts/AggregatedReward'

export const PREV_ROUTES = ['/library', '/rewards', '/quests']

const GamesList = () => {
  const { user, updateUserData } = useContext(AuthContext)
  const { previousPage } = useContext(HistoryContext)
  const { nativeAppDetails } = useContext(NativeAppContext)

  const [showBackgroundPigsAnimation, setShowBackgroundPigsAnimation] = useState(false)

  const notificationsRef = useRef(null)

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  const { config: TOSPromptConfig } = useTOSPromptConfig()
  useEffect(() => {
    if (user) {
      if (window.ReactNativeWebView && showTOSPromptForUser(user, TOSPromptConfig?.updatedAt)) {
        navigate('/accept-tos')
        hideLoader()
      }

      const offerActionsRef = ref(db, `offer_actions/${user?.id}`)
      onChildAdded(offerActionsRef, () => {
        queryClient.invalidateQueries({
          queryKey: 'featuredGameOffers',
        })
      })
    }
  }, [user])

  const { data: config, isLoading: loadingPiggyChestDropRates } = useQuery(
    'piggyChestDropRates',
    () => getPiggyChestConfig(),
    { onError: handleError }
  )

  const { data: allUnclaimedRewards, isLoading: loadingAllUnclaimedRewards } = useQuery(
    'allUnclaimedRewards',
    () => getAllUnclaimedRewards(),
    { onError: handleError }
  )

  const { data: offers, isLoading: loadingFeaturedGameOffers } = useQuery(
    'featuredGameOffers',
    () => getFeaturedGameOffers(user?.id),
    {
      onError: handleError,
      enabled: !!user,
      retry: (failureCount, error) => {
        // Handle and report timeout error
        if (error.message.includes('timeout')) {
          captureEvent({
            message: ERRORS.FEATURED_GAME_TIMEOUT_ERROR,
            level: 'error',
            extra: { err: error },
          })

          return failureCount < 1 // Retry only 1 time
        }
        return failureCount < 3 // Retry up to 3 times
      },
    }
  )

  useEffect(() => {
    if (
      offers?.activatedRow.find(
        (offer) => offer.offerType === OFFER_TYPE.REWARDED_PLAYTIME || offer.batchPlaytimeConfig
      )
    ) {
      sendRNMessage({
        status: REACT_NATIVE_STATUS.START_PLAYTIME_TRACKING_IN_FOREGROUND,
      })
    }
  }, [offers?.activatedRow])

  const { isLoading: loadingRefreshQuests } = useQuery(
    'assignActiveQuests',
    () => refreshUserQuests(user),
    {
      onSuccess: (data) => data && updateUserData(data.updatedUser),
      onError: handleError,
    }
  )

  const { data: suggestedQuest, isLoading: loadingQuests } = useQuery('quests', getSuggestedQuest, {
    onError: handleError,
  })

  const isFetching =
    loadingPiggyChestDropRates ||
    loadingRefreshQuests ||
    loadingQuests ||
    loadingFeaturedGameOffers ||
    loadingAllUnclaimedRewards

  useEffect(() => {
    isFetching ? showLoader() : hideLoader()
  }, [isFetching])

  const checkDSVisibility = useCallback(
    () => checkDailyStreakVisibility(nativeAppDetails?.versionCode),
    [nativeAppDetails?.versionCode]
  )

  return (
    <Layout isLoading={isLoading}>
      {showBackgroundPigsAnimation && <BackgroundPigsAnimation />}
      {user && (!user?.onboardingStep || user?.onboardingStep === ONBOARDING_STEP.COMPLETE) && (
        <Container maxWidth="sm" className="gamesListNotifications" ref={notificationsRef}>
          <RateAppAlert />
          {checkDSVisibility() && (
            <DailyStreakView setShowBackgroundPigsAnimation={setShowBackgroundPigsAnimation} />
          )}
          {user.isChestAvailableForPurchase && (
            <PiggyBankChest
              config={config}
              setShowBackgroundPigsAnimation={setShowBackgroundPigsAnimation}
            />
          )}
          {allUnclaimedRewards?.rewards?.length > 0 && (
            <AggregatedReward
              rewards={allUnclaimedRewards?.rewards}
              aggregatedCoins={allUnclaimedRewards?.aggregatedCoins}
              aggregatedPiggyBanks={allUnclaimedRewards?.aggregatedPiggyBanks}
              setShowBackgroundPigsAnimation={setShowBackgroundPigsAnimation}
            />
          )}
          {suggestedQuest && <QuestAlert quest={suggestedQuest} />}
        </Container>
      )}

      <SlideLayout
        prevRoutes={PREV_ROUTES}
        direction="right"
        swipeIn={!isLoading}
        isSwiping={previousPage?.startsWith('/games/')}
      >
        <Content
          isLoading={isLoading}
          offers={offers}
          previousPage={previousPage}
          notificationsRef={notificationsRef}
        />
      </SlideLayout>
    </Layout>
  )
}

export default GamesList
