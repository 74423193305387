import React from 'react'
import { Box, Typography } from '@mui/material'

//components
import PriceText from 'components/giftCards/PriceText'
//styles
import './index.css'
//types
import { RedemptionHistoryItemProps } from 'types'
//utils
import { formatDate, formatNumber } from 'utils/utils'

const RedemptionHistoryItem = ({
  title,
  claimedAt,
  value,
  spentCoins,
  spentPiggyBanks,
  imageUrl,
}: RedemptionHistoryItemProps) => {
  const formattedCoins = formatNumber(spentCoins)
  const formattedPigs = formatNumber(spentPiggyBanks)
  return (
    <Box sx={styles.inner}>
      <Box sx={styles.content}>
        <img src={imageUrl} className="accountBalanceRedemptionDetailsImage" />
        <Box sx={styles.contentText}>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="body2">Redeemed: {formatDate(claimedAt)}</Typography>
          <Typography variant="body2">Value: {value}</Typography>
        </Box>
      </Box>
      <Box sx={styles.footer}>
        <Box sx={styles.footerCurrency}>
          <PriceText
            userBalance={spentPiggyBanks}
            giftCardValue={spentPiggyBanks}
            value={formattedPigs}
            isPig={true}
          />
          {!!spentCoins && (
            <PriceText
              userBalance={spentCoins}
              giftCardValue={spentCoins}
              value={formattedCoins}
              isPig={false}
            />
          )}
        </Box>
        <a href="#" className="accountBalanceRedemptionDetailsLink">
          Details...
        </a>
      </Box>
    </Box>
  )
}

const styles = {
  inner: {
    padding: '12px 32px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    borderBottom: '1px solid #E4E4E7',
  },
  content: {
    display: 'flex',
    gap: '16px',
  },
  contentText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  footerCurrency: {
    display: 'flex',
    gap: '12px',
  },
}

export default RedemptionHistoryItem
