import React from 'react'

//assets
import Arrow from 'assets/dailyStreak/arrow.svg'
//components
import FireIcon from '../../FireIcon'
import BottomButton from 'components/common/BottomButton'
//styles
import './index.css'

const DailyStreakEnd = ({
  streakCount,
  onClose,
  onBackClick,
  onConfirmClick,
}: {
  streakCount: number
  onClose: () => void
  onBackClick: () => void
  onConfirmClick: () => void
}) => {
  return (
    <div className="dsendp_main-container">
      <div className="content">
        <img
          className="close-btn"
          src="/images/modalCloseButton.png"
          alt="close"
          onClick={onClose}
        />
        <p className="title">End your streak?</p>
        <p className="sub-title">
          Are you sure you want to end your streak? Your streak will reset to 0.
        </p>
        <div className="streak-balance">
          <FireIcon type="enabled" width={60} value={streakCount} />
          <img src={Arrow} alt="arrow" />
          <FireIcon type="enabled" width={60} value={0} />
        </div>
        <div className="action-btns">
          <BottomButton className="back-btn" handleClick={onBackClick}>
            Back
          </BottomButton>
          <BottomButton className="end-btn" handleClick={onConfirmClick} style={styles.endButton}>
            Confirm
          </BottomButton>
        </div>
      </div>
    </div>
  )
}

const styles = {
  endButton: {
    background: '#F87171',
    '&:hover': {
      background: '#FCA5A5',
    },
  },
}

export default DailyStreakEnd
