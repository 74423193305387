import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import toast from 'react-hot-toast'
import { Referral } from '@playback-rewards/shared-libraries'

//constants
import QueryKeys from 'constants/queryKeys'
//services
import { getCompletedReferrals } from 'services/referalService'

const useQueryGetCompletedReferrals = () => {
  const { data, isLoading } = useQuery<AxiosResponse<Referral[], Error>>(
    QueryKeys.GET_COMPLETED_REFERRALS,
    getCompletedReferrals,
    {
      onError: (error) => {
        toast.error(
          ((error as AxiosError)?.response?.data as Error).message || 'Something went wrong!'
        )
      },
    }
  )

  return {
    isLoadingCompletedReferrals: isLoading,
    completedReferrals: data?.data ?? [],
  }
}

export default useQueryGetCompletedReferrals
