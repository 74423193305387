import { useContext } from 'react'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import toast from 'react-hot-toast'
import { CurrencyType } from '@playback-rewards/shared-libraries'

//contexts
import AuthContext from 'context/AuthContext'
//services
import { extendDailyStreak } from 'services/dailyStreak'
import { getUserById } from 'services/userService'

const useMutationExtendStreak = (
  updateStreakStatus: () => void,
  setShowExpiringPopup: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user, updateUserData } = useContext(AuthContext) as any
  const { mutate: extendStreakMutate } = useMutation(
    (currencyType: CurrencyType) => extendDailyStreak(currencyType),
    {
      onSuccess: async () => {
        const data = await getUserById(user.id)
        updateUserData(data.data)
        updateStreakStatus()
        setShowExpiringPopup(false)
      },
      onError: (error) => {
        toast.error(
          ((error as AxiosError)?.response?.data as Error).message || 'Something went wrong!'
        )
      },
    }
  )
  return { extendStreakMutate }
}

export default useMutationExtendStreak
