import React, { useContext, useMemo } from 'react'
import { Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { FTimestampFunc } from '@playback-rewards/shared-libraries'

//assets
import CheckMark from 'assets/referral/check-mark.svg'
//components
import ProgressBar from 'components/ProgressBar'
import ReferralItemInfoText from './ReferralItemInfoText'
//contexts
import AuthContext from 'context/AuthContext'
//styles
import './index.css'
//types
import { ReferralItemProps } from 'types'
//utils
import { formatNumber } from 'utils/utils'

const ReferralItem = ({ data, referralConfig }: ReferralItemProps) => {
  const { user } = useContext(AuthContext)
  const { isPaidOut, activationTimestamp } = data
  const { totalPiggyBanksToEarn } = referralConfig
  const earnedPiggyBanksByReferee = useMemo(
    () => Math.min(data.earnedPiggyBanksByReferee, totalPiggyBanksToEarn),
    [data.earnedPiggyBanksByReferee, totalPiggyBanksToEarn]
  )
  const isMyReferrerView = useMemo(
    () => user?.id === data.referee.userId,
    [user?.id, data.referee.userId]
  )
  const { bonusInUSD, bonusCoins, bonusPiggyBanks } = useMemo(
    () => (isMyReferrerView ? data.referee : data.referrer),
    [isMyReferrerView, data]
  )
  const formattedBonusPiggyBanks = useMemo(() => formatNumber(bonusPiggyBanks), [bonusPiggyBanks])
  const formattedBonusCoins = useMemo(() => formatNumber(bonusCoins), [bonusCoins])
  const title = useMemo(() => {
    if (isMyReferrerView) {
      return `You were referred by ${data.referrer.name}`
    }

    return `You referred ${data.referee.name}`
  }, [isMyReferrerView, data])
  const progress = useMemo(
    () => Math.min(Math.round((earnedPiggyBanksByReferee / totalPiggyBanksToEarn) * 100), 100),
    [totalPiggyBanksToEarn, earnedPiggyBanksByReferee]
  )
  const pendingToEarn = useMemo(
    () => totalPiggyBanksToEarn - earnedPiggyBanksByReferee,
    [totalPiggyBanksToEarn, earnedPiggyBanksByReferee]
  )
  const isReadyToRedeem = useMemo(
    () => totalPiggyBanksToEarn === earnedPiggyBanksByReferee,
    [totalPiggyBanksToEarn, earnedPiggyBanksByReferee]
  )

  return (
    <div
      className={`referral-item ${isReadyToRedeem && 'ready-to-redeem'} ${isPaidOut && 'complete'}`}
    >
      <div
        className={`top-row  ${isReadyToRedeem && 'ready-to-redeem'} ${isPaidOut && 'complete'}`}
      >
        <p className="title">{title}</p>
        <p className="date">
          {DateTime.fromJSDate(FTimestampFunc.toDate(activationTimestamp)).toFormat('MM/dd/yyyy')}
        </p>
      </div>
      {isPaidOut ? (
        <>
          <p className="bonus-earned-text">
            You earned <strong>{formattedBonusPiggyBanks}</strong> <img src="/images/pig.png" />{' '}
            {bonusCoins > 0 && (
              <>
                & <strong>{formattedBonusCoins}</strong> <img src="/images/coin.png" />
              </>
            )}
          </p>
          <div className="progress-bar-complete">
            <ProgressBar
              fillerType="referral-completed"
              completed={100}
              style={styles.progressBarComplete}
            />
            <img className="check-mark" src={CheckMark} alt="check-mark" />
          </div>
        </>
      ) : (
        <>
          <div className="progress-bar">
            <ProgressBar fillerType="gold" completed={progress} style={styles.progressBar} />
            <Typography variant="small" className={`value ${isReadyToRedeem && 'ready-to-redeem'}`}>
              {isMyReferrerView ? 'You' : data.referee.name} earned: {earnedPiggyBanksByReferee} /{' '}
              {totalPiggyBanksToEarn} <img src="/images/pig.png" />
            </Typography>
          </div>
          <ReferralItemInfoText
            refereeName={data.referee.name}
            isMyReferrerView={isMyReferrerView}
            isReadyToRedeem={isReadyToRedeem}
            pendingToEarn={pendingToEarn}
            bonusInUSD={bonusInUSD}
          />
        </>
      )}
    </div>
  )
}

const styles = {
  progressBarComplete: {
    border: '3px solid white',
    boxShadow: '0px 2px 8px -1px rgba(0, 0, 0, 0.12)',
    padding: 0,
  },
  progressBar: {
    border: '1px solid #FFC11F66',
    boxShadow: '2px 4px 12px 0px #FF962B1F',
    padding: '1px',
  },
}

export default ReferralItem
