import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useQuery } from 'react-query'
import { Duration } from 'luxon'
import {
  createDefaultDailyStreak,
  DailyStreak,
  DailyStreakStatus,
  DailyStreakUtil,
  getDailyRewardPiggyBanks,
} from '@playback-rewards/shared-libraries'

//components
import DailyStreakCard from './DailyStreakCard'
import DailyStreakInfo from './DailyStreakInfo'
import DailyStreakClaimPopup from './DailyStreakClaimPopup'
import DailyStreakWebPopup from './DailyStreakWebPopup'
import DailyStreakExpiringEndPopup from './DailyStreakExpiringEndPopup'
//constants
import { REACT_NATIVE_STATUS } from 'constants/index'
import QueryKeys from 'constants/queryKeys'
//contexts
import AuthContext from 'context/AuthContext'
//hooks
import useDeltaTime from 'hooks/useDeltaTime'
import useMutationAdWatched from './__hooks/useMutationAdWatched'
import useMutationExtendStreak from './__hooks/useMutationExtendStreak'
import useMutationResetStreak from './__hooks/useMutationResetStreak'
//services
import { getStreakConfig } from 'services/dailyStreak'
//utils
import { sendRNMessage } from 'utils/utils'

const DailyStreakView = ({
  setShowBackgroundPigsAnimation,
}: {
  setShowBackgroundPigsAnimation: Dispatch<SetStateAction<boolean>>
}) => {
  const { delta } = useDeltaTime()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user } = useContext(AuthContext) as any

  //Query hooks
  const { data } = useQuery(QueryKeys.GET_STREAK_CONFIG, getStreakConfig)
  const streakConfig = data?.data
  const isWeb = useRef(window.ReactNativeWebView === undefined).current
  const streakData: DailyStreak = useMemo(() => {
    if (!user.dailyStreak) {
      return createDefaultDailyStreak()
    }

    return user.dailyStreak
  }, [user?.dailyStreak, createDefaultDailyStreak])
  const dailyStreakUtil = useMemo(() => {
    if (!streakData || !streakConfig) {
      return null
    }
    return new DailyStreakUtil(streakData, streakConfig)
  }, [streakData, streakConfig])
  const dailyReward = useMemo(() => {
    if (!streakData.streakCount || !streakConfig) {
      return 0
    }
    return getDailyRewardPiggyBanks(streakData.streakCount, streakConfig)
  }, [streakData.streakCount, streakConfig, getDailyRewardPiggyBanks])
  const [streakStatus, setStreakStatus] = useState<DailyStreakStatus | undefined>()
  const [streakReadyIn, setStreakReadyIn] = useState(0)
  const [streakExpiresIn, setStreakExpiresIn] = useState(0)
  const [showWebPopup, setShowWebPopup] = useState(false)
  const [showDSInfo, setShowDSInfo] = useState(false)
  const [showClaimPopup, setShowClaimPopup] = useState(false)
  const [showExpiringPopup, setShowExpiringPopup] = useState(false)

  const getNow = useCallback(
    (): Date | null => (delta ? new Date(Date.now() + delta) : null),
    [delta]
  )

  const updateStreakStatus = useCallback(() => {
    const now = getNow()
    if (!now || !dailyStreakUtil) {
      return
    }

    setStreakStatus(dailyStreakUtil.getStreakStatus(now))
  }, [delta, dailyStreakUtil, getNow])

  //Mutation hooks
  const { adWatchedMutate, isLoadingAdWathced } = useMutationAdWatched(
    setShowClaimPopup,
    setShowBackgroundPigsAnimation
  )
  const { extendStreakMutate } = useMutationExtendStreak(updateStreakStatus, setShowExpiringPopup)
  const { resetStreakMutate } = useMutationResetStreak(updateStreakStatus, setShowExpiringPopup)

  useEffect(() => {
    updateStreakStatus()
  }, [updateStreakStatus])

  // Daily Streak Ready Again Timer
  useEffect(() => {
    if (streakStatus !== DailyStreakStatus.CLAIMED) {
      return
    }

    const getReadyInTime = () => {
      const now = getNow()
      if (!now || !dailyStreakUtil) {
        return 0
      }

      return dailyStreakUtil.getNextReward(now)
    }

    let diff = getReadyInTime()
    setStreakReadyIn(diff)

    const interval = setInterval(() => {
      diff = getReadyInTime()
      if (diff <= 0) {
        clearInterval(interval)
        return updateStreakStatus()
      }
      setStreakReadyIn(diff)
    }, 1000)

    return () => clearInterval(interval)
  }, [streakStatus, setStreakReadyIn, dailyStreakUtil, delta, getNow])
  // Daily Streak Expires In Timer
  useEffect(() => {
    if (streakStatus !== DailyStreakStatus.EXPIRING) {
      return
    }

    const getExpiresInTime = () => {
      const now = getNow()
      if (!now || !dailyStreakUtil) {
        return 0
      }

      return dailyStreakUtil.getStreakExtensionEnd(now)
    }

    let diff = getExpiresInTime()
    setStreakExpiresIn(diff)

    const interval = setInterval(async () => {
      diff = getExpiresInTime()
      if (diff <= 0) {
        clearInterval(interval)
        return resetStreakMutate()
      }
      setStreakExpiresIn(diff)
    }, 1000)

    return () => clearInterval(interval)
  }, [streakStatus, setStreakExpiresIn, dailyStreakUtil, delta, getNow])
  //Reset streak if status is Expired
  useEffect(() => {
    if (streakStatus === DailyStreakStatus.EXPIRED) {
      resetStreakMutate()
    }
  }, [streakStatus, resetStreakMutate])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleReceiveMessage = (nativeEvent: any) => {
    if (nativeEvent.data.status === REACT_NATIVE_STATUS.AD_VIEW_COMPLETED) {
      adWatchedMutate()
    }
  }
  useEffect(() => {
    window.addEventListener('message', handleReceiveMessage)

    return () => {
      window.removeEventListener('message', handleReceiveMessage)
    }
  }, [])
  const resetTimeFormat1 = useMemo(
    () => (streakReadyIn < 60000 ? "ss's'" : "hh'h' mm'm'"),
    [streakReadyIn]
  )
  const resetTimeFormat2 = useMemo(() => {
    if (streakReadyIn < 60000) {
      return "ss's'"
    }
    if (streakReadyIn < 3600000) {
      return "mm'm'"
    }
    return "h'h'"
  }, [streakReadyIn])

  const handleOnTileClick = () => {
    if (isLoadingAdWathced) {
      return // disable click during api call
    }

    if (isWeb) {
      setShowWebPopup(true)
    } else if (streakStatus === DailyStreakStatus.READY_FOR_AD) {
      sendRNMessage({ status: REACT_NATIVE_STATUS.SHOW_APPLOVIN_AD })
    } else if (streakStatus === DailyStreakStatus.CLAIMED) {
      setShowDSInfo(true)
    } else if (streakStatus === DailyStreakStatus.EXPIRING) {
      setShowExpiringPopup(true)
    }
  }

  if (!streakData || !streakConfig || delta === null) {
    return null
  }

  return (
    <>
      <DailyStreakCard
        isWeb={isWeb}
        streakStatus={streakStatus || DailyStreakStatus.READY_FOR_AD}
        streakCount={streakData.streakCount}
        streakReadyIn={Duration.fromMillis(streakReadyIn).toFormat(resetTimeFormat1)}
        streakExpiresIn={Duration.fromMillis(streakExpiresIn).toFormat('hh:mm:ss')}
        streakConfig={streakConfig}
        disableClick={isLoadingAdWathced}
        onTileClick={handleOnTileClick}
        onButtonClick={handleOnTileClick}
      />
      <DailyStreakWebPopup open={showWebPopup} onClose={() => setShowWebPopup(false)} />
      <DailyStreakInfo
        open={showDSInfo}
        streakStatus={streakStatus || DailyStreakStatus.READY_FOR_AD}
        streakCount={streakData.streakCount}
        streakConfig={streakConfig}
        streakReadyIn={Duration.fromMillis(streakReadyIn).toFormat(resetTimeFormat1)}
        onClose={() => setShowDSInfo(false)}
      />
      <DailyStreakClaimPopup
        open={showClaimPopup}
        streakCount={streakData.streakCount}
        dailyReward={dailyReward}
        streakReadyIn={Duration.fromMillis(streakReadyIn).toFormat(resetTimeFormat2)}
        setShowBackgroundPigsAnimation={setShowBackgroundPigsAnimation}
        onClose={() => setShowClaimPopup(false)}
      />
      <DailyStreakExpiringEndPopup
        open={showExpiringPopup}
        streakCount={streakData.streakCount}
        streakExpiresIn={Duration.fromMillis(streakExpiresIn).toFormat('hh:mm:ss')}
        userPigs={user.piggyBanks}
        userCoins={user.points}
        extensionPiggiesCost={streakConfig.extensionCost.piggyBanks}
        extensionCoinsCost={streakConfig.extensionCost.coins}
        onCurrencyClick={(currencyType) => extendStreakMutate(currencyType)}
        onConfirmClick={resetStreakMutate}
        onClose={() => setShowExpiringPopup(false)}
      />
    </>
  )
}

export default DailyStreakView
