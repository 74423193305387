import React, { useMemo } from 'react'

//components
import DailyStreakReviveButton from '../../DailyStreakReviveButton'
import BottomButton from 'components/common/BottomButton'
//styles
import './index.css'
import { CurrencyType } from '@playback-rewards/shared-libraries'

const DailyStreakExpiring = ({
  streakCount,
  streakExpiresIn,
  userPigs,
  userCoins,
  extensionPiggiesCost,
  extensionCoinsCost,
  onClose,
  onCurrencyClick,
  onEndClick,
}: {
  streakCount: number
  streakExpiresIn: string
  userPigs: number
  userCoins: number
  extensionPiggiesCost: number
  extensionCoinsCost: number
  onClose: () => void
  onCurrencyClick: (currencyType: CurrencyType) => void
  onEndClick: () => void
}) => {
  const insufficientPigs = useMemo(
    () => userPigs < extensionPiggiesCost,
    [userPigs, extensionPiggiesCost]
  )
  const insufficientCoins = useMemo(
    () => userCoins < extensionCoinsCost,
    [userCoins, extensionCoinsCost]
  )

  return (
    <div className="dsep_main-container">
      <div className="content">
        <img
          className="close-btn"
          src="/images/modalCloseButton.png"
          alt="close"
          onClick={onClose}
        />
        <p className="title">
          You lost your {streakCount} {streakCount > 1 ? 'days' : 'day'} streak!
        </p>
        <p className="sub-title">
          It looks like you missed claiming your daily streak. Would you like to save your streak?
        </p>
        <p className="reset-text">
          Streak automatically resets: <span className="time">{streakExpiresIn}</span>
        </p>
        <p className="info-text">Save your streak:</p>
        <div className="revive-actions">
          <DailyStreakReviveButton
            streakCount={streakCount}
            currencyType={CurrencyType.PIGGY_BANKS}
            extensionPiggiesCost={extensionPiggiesCost}
            extensionCoinsCost={extensionCoinsCost}
            disabled={insufficientPigs}
            onButtonClick={() => onCurrencyClick(CurrencyType.PIGGY_BANKS)}
          />
          <span className="or">OR</span>
          <DailyStreakReviveButton
            streakCount={streakCount}
            currencyType={CurrencyType.COINS}
            extensionPiggiesCost={extensionPiggiesCost}
            extensionCoinsCost={extensionCoinsCost}
            disabled={insufficientCoins}
            onButtonClick={() => onCurrencyClick(CurrencyType.COINS)}
          />
        </div>
        {(insufficientPigs || insufficientCoins) && (
          <p className="insufficient-currency">
            If you would like to earn more <img src="/images/pig.png" /> or{' '}
            <img src="/images/coin.png" /> before you save your streak, you can close this window.
          </p>
        )}
        <p className="info-text">End your streak:</p>
        <BottomButton className="end-btn" handleClick={onEndClick} style={styles.endButton}>
          End
        </BottomButton>
      </div>
    </div>
  )
}

const styles = {
  endButton: {
    background: '#F87171',
    '&:hover': {
      background: '#FCA5A5',
    },
  },
}

export default DailyStreakExpiring
