export const generateReferralLink = (referralId: string | undefined) => {
  if (!referralId) {
    return
  }

  if (process.env.REACT_APP_NODE_ENV === 'development') {
    return `r.pbr.gg/r/refer_local/${referralId}`
  }
  if (
    process.env.REACT_APP_NODE_ENV === 'staging' ||
    process.env.REACT_APP_NODE_ENV === 'release-candidate'
  ) {
    return `r.pbr.gg/r/refer_staging/${referralId}`
  }
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    return `r.pbr.gg/r/refer/${referralId}`
  }
}
