import React from 'react'

//components
import BottomButton from 'components/common/BottomButton'
//styles
import './index.css'

const influencerFeatures = [
  'Custom branded link for you to share where you want.',
  'Potential to earn more with each person that you refer.',
  'Custom offers to share with your followers.',
]

const InfluencerInfo = ({ onApplyClick }: { onApplyClick: () => void }) => {
  return (
    <div className="influencer-info">
      <p className="heading">Influencer Program</p>
      <p className="info">
        Are you an influencer or just have a lot of friends? If you think you can refer more than 20
        people, our influencer program might be for you!
      </p>
      {influencerFeatures.map((feature, index) => (
        <div key={`feature-${index}`} className="feature">
          <div className="number">
            <p className="number text">{index + 1}</p>
          </div>
          <p className="text">{feature}</p>
        </div>
      ))}
      <BottomButton className="apply-btn" handleClick={onApplyClick}>
        Apply Now
      </BottomButton>
    </div>
  )
}

export default InfluencerInfo
