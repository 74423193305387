import _ from 'lodash'

//constants
import { QUEST_PROGRESS_TYPE } from 'constants/index'
//types
import { UserQuest } from 'types'

export const getProgressText = (quest: UserQuest) => {
  if (!quest.progressMeter) {
    return null
  }

  const progressType =
    quest.progressMeter.type === QUEST_PROGRESS_TYPE.MINUTES_PLAYED &&
    quest.progressMeter.offersNeededToComplete > 1
      ? QUEST_PROGRESS_TYPE.MULTIPLE_QUESTS
      : quest.progressMeter.type

  switch (progressType) {
    case QUEST_PROGRESS_TYPE.REFERRED_EARNED_GIFT_CARD:
      return {
        progress: (quest.progressMeter.trackedValue * 100) / quest.progressMeter.value,
        text: `${quest.progressMeter.trackedValue} / ${quest.progressMeter.value} gift cards redeemed`,
      }
    case QUEST_PROGRESS_TYPE.DOLLARS_SPEND:
    case QUEST_PROGRESS_TYPE.PLAYBACK_DOLLARS_SPEND:
    case QUEST_PROGRESS_TYPE.REFERRED_DOLLARS_SPEND:
      return {
        progress: (quest.progressMeter.trackedValue * 100) / quest.progressMeter.value,
        text: `$${quest.progressMeter.trackedValue} / $${quest.progressMeter.value} spent`,
      }
    case QUEST_PROGRESS_TYPE.MINUTES_PLAYED: {
      const targetValue = quest.progressMeter.value

      const trackedValues = Object.values(quest.progressMeter.rawTrackedValue)
      const highestProgress = trackedValues.reduce((max, item) => Math.max(max, item.value), 0)
      let progressTime = Math.floor(highestProgress / 60)

      //Timer playtime tracking
      if (quest.completedAt) {
        const trackedAtTime = quest.completedAt._seconds * 1000
        const minutesDifference = Math.ceil((trackedAtTime - new Date().getTime()) / (60 * 1000))

        progressTime =
          minutesDifference < 0
            ? quest.progressMeter.value
            : quest.progressMeter.value - minutesDifference
      }

      return {
        progress: (progressTime * 100) / targetValue,
        text: `${progressTime} / ${targetValue} min`,
      }
    }
    case QUEST_PROGRESS_TYPE.MULTIPLE_QUESTS: {
      const completedQuestsAmount = Object.values(quest.progressMeter.rawTrackedValue).filter(
        (trackedValue) =>
          trackedValue.completedAt &&
          new Date(trackedValue.completedAt._seconds * 1000) < new Date()
      ).length

      return {
        progress: (completedQuestsAmount * 100) / quest.progressMeter.offersNeededToComplete,
        text: `${completedQuestsAmount} / ${quest.progressMeter.offersNeededToComplete} games`,
      }
    }
    default:
      return null
  }
}
