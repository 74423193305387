//constants
import { DAILY_STREAK_ANDROID_VERSION_CODE } from 'constants/index'

export const EACH_STREAK_SET_COUNT = 30

export const STREAK_BREAK_POINTS = [1, 10, 20, 30]

export const getMinDivisibleByNumber = (value: number, number: number) => {
  if (value % number === 0) {
    return value - number
  } else {
    return Math.ceil(value / number) * number - number
  }
}

export const checkDailyStreakVisibility = (versionCode: number): boolean =>
  window.ReactNativeWebView ? versionCode >= DAILY_STREAK_ANDROID_VERSION_CODE : true
