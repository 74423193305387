import axiosWebInstance from 'axiosWebInstance'
import { axiosBackendInstance } from '../axios'

export const getCurrentReferralOffer = async () => {
  return axiosBackendInstance.get('/api/v1/referral')
}

export const getReferralTrackedRecord = async () => {
  return axiosBackendInstance.get('/api/v1/referral/user')
}

export const getUserReferralRewards = async (filter: string) => {
  return axiosBackendInstance.get(`/api/v1/referral/rewards?filter=${filter}`)
}

export const claimReferralReward = async (referralRewardId: string) => {
  return axiosBackendInstance.post(`/api/v1/referral/claim/${referralRewardId}`)
}

export const getUserReferralId = async () => {
  return axiosBackendInstance.get('/api/v1/referral/code')
}

export const getUserByReferralId = async (referralId: string) => {
  return axiosBackendInstance.get(`/api/v1/referral/user/${referralId}`)
}

export const getReferralConfig = async () => {
  return axiosWebInstance.get('/api/v1/referral/config')
}

export const getUserReferralConfig = async () => {
  return axiosWebInstance.get('/api/v1/referral/user/config')
}

export const getReferrer = async () => {
  return axiosWebInstance.get('/api/v1/referral/referrer')
}

export const getReferees = async () => {
  return axiosWebInstance.get('/api/v1/referral/referees')
}

export const getCompletedReferrals = async () => {
  return axiosWebInstance.get('/api/v1/referral/completed-referrals')
}
