import { AxiosResponse } from 'axios'

//axios
import { axiosBackendInstance } from '../axios'
import axiosWebInstance from '../axiosWebInstance'
//types
import { PiggyBankChestConfig, PiggyChestReward, User } from 'types'

export const getPiggyChestConfig = async (): Promise<
  AxiosResponse<PiggyBankChestConfig, Error>
> => {
  return axiosBackendInstance.get('/api/v1/chest/config')
}

export const buyPiggyBankChest = async (): Promise<
  AxiosResponse<{ updatedUser: User; reward: PiggyChestReward }, Error>
> => {
  return axiosWebInstance.post('/api/v1/chest')
}
