import React, { useMemo } from 'react'
import { FTimestampFunc } from '@playback-rewards/shared-libraries'

//components
import ReferralList from './ReferralList'
//styles
import './index.css'
//types
import { MyReferralsProps } from 'types'

const MyReferrals = ({
  inProgressReferees,
  completedReferrals,
  referralConfig,
}: MyReferralsProps) => {
  const sortedInProgressReferees = useMemo(
    () =>
      inProgressReferees.sort((a, b) => b.earnedPiggyBanksByReferee - a.earnedPiggyBanksByReferee),
    [inProgressReferees]
  )
  const sortedCompletedReferrals = useMemo(
    () =>
      completedReferrals.sort(
        (a, b) =>
          FTimestampFunc.toMillis(b.activationTimestamp) -
          FTimestampFunc.toMillis(a.activationTimestamp)
      ),
    [completedReferrals]
  )

  return (
    <div className="my-referrals">
      <p className="heading">Your Referrals</p>
      {sortedInProgressReferees.length <= 0 && sortedCompletedReferrals.length <= 0 ? (
        <p className="no-data">
          You haven’t referred anyone to Playback yet. Share your link above with friends to earn
          referral rewards!
        </p>
      ) : (
        <>
          {sortedInProgressReferees.length > 0 && (
            <ReferralList
              state="IN-PROGRESS"
              referrals={sortedInProgressReferees}
              referralConfig={referralConfig}
            />
          )}
          {sortedCompletedReferrals.length > 0 && (
            <ReferralList
              state="COMPLETED"
              referrals={sortedCompletedReferrals}
              referralConfig={referralConfig}
            />
          )}
        </>
      )}
    </div>
  )
}

export default MyReferrals
