import React, { useCallback, useMemo, useState } from 'react'
import { Container } from '@mui/material'
import toast from 'react-hot-toast'

//assets
import FriendsHero from 'assets/footer/friends-hero.svg'
//components
import Layout from 'components/layout/Games'
import ShareModal from 'pages/ReferFriend/components/ShareModal'
import ReferralInfo from './ReferralInfo'
import ReferralItem from './ReferralItem'
import MyReferrals from './MyReferrals'
import InfluencerInfo from './InfluencerInfo'
//constants
import { REFERRAL_INFLUENCER_ONBOARDING_LINK, USER_PLATFORM } from 'constants/index'
//hooks
import useQueryGetReferralConfig from './__hooks/useQueryGetReferralConfig'
import useQueryGetUserReferralConfig from './__hooks/useQueryGetUserReferralConfig'
import useQueryGetReferrer from './__hooks/useQueryGetReferrer'
import useQueryGetReferees from './__hooks/useQueryGetReferees'
import useQueryGetCompletedReferrals from './__hooks/useQueryGetCompletedReferrals'
//styles
import './index.css'
//types
import { FriendsShareOption } from 'types'
//utils
import { ReferFriendLink, ReferFriendMail, ReferFriendMessage } from 'utils/icons'
import { generateReferralLink } from 'utils/referral'
import { getPlatform, objectEncode } from 'utils/utils'
import FullPageLoader from 'components/pageLoader'

const Friends = () => {
  //Query hooks
  const { referralConfigData, isLoadingReferralConfig } = useQueryGetReferralConfig()
  const { userReferralConfig, isLoadingUserReferralConfig } = useQueryGetUserReferralConfig()
  const { referrer, isLoadingReferrer } = useQueryGetReferrer()
  const { referees, isLoadingReferees } = useQueryGetReferees()
  const { completedReferrals, isLoadingCompletedReferrals } = useQueryGetCompletedReferrals()

  const [disableCopy, setDisableCopy] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const referralLink = useMemo(
    () => generateReferralLink(userReferralConfig?.referralId),
    [userReferralConfig?.referralId]
  )
  const referralConfig = useMemo(() => {
    if (userReferralConfig?.referralConfig && referralConfigData?.totalPiggyBanksToEarn) {
      return {
        ...userReferralConfig.referralConfig,
        totalPiggyBanksToEarn: referralConfigData?.totalPiggyBanksToEarn,
      }
    }
    return referralConfigData
  }, [referralConfigData, userReferralConfig])
  const referralMessage = useMemo(() => {
    return `Earn cash back on your game purchases with me at Playback! https://${referralLink}`
  }, [referralLink])
  const isLoading = [
    isLoadingReferralConfig,
    isLoadingUserReferralConfig,
    isLoadingReferrer,
    isLoadingReferees,
    isLoadingCompletedReferrals,
  ].some(Boolean)

  const showShareModal = () => setIsModalVisible(true)
  const hideShareModal = () => setIsModalVisible(false)
  const handleCopyClick = () => {
    navigator.clipboard.writeText(`https://${referralLink}`)
    toast.success('Copied to clipboard')
    setDisableCopy(true)

    setTimeout(() => setDisableCopy(false), 2000)
  }
  const handleShare = () => {
    navigator.share({
      text: referralMessage,
    })
  }
  const generateShareLink = useCallback(
    (type: 'sms' | 'email') => {
      const platform = getPlatform()
      const encodedBody = objectEncode({
        body: referralMessage,
      })

      if (type === 'email') {
        return `mailto:?${encodedBody}`
      }

      const smsPrefix = platform === USER_PLATFORM.ANDROID ? 'sms:?' : 'sms:&'

      return smsPrefix + encodedBody
    },
    [referralMessage]
  )

  const handleApplyClick = () => {
    window.open(REFERRAL_INFLUENCER_ONBOARDING_LINK, '_blank')
  }

  const shareOptions: FriendsShareOption[] = useMemo(() => {
    let options = [
      {
        Icon: ReferFriendMail,
        href: generateShareLink('email'),
      },
      {
        Icon: ReferFriendLink,
        handleClick: window.ReactNativeWebView === undefined ? handleShare : showShareModal,
      },
    ]
    if (getPlatform() !== 'unknown') {
      options = [
        {
          Icon: ReferFriendMessage,
          href: generateShareLink('sms'),
        },
        ...options,
      ]
    }
    return options
  }, [generateShareLink])

  if (!referralConfig) {
    return null
  }

  return (
    <Layout isLoading={isLoading}>
      {isLoading && <FullPageLoader isVisible={true} />}
      <ShareModal
        showModal={isModalVisible}
        hideModal={hideShareModal}
        shareLink={referralMessage}
        handleCopy={handleCopyClick}
        referralLink={referralLink}
        copyDisabled={disableCopy}
      />
      <Container maxWidth="sm" sx={{ padding: 0 }}>
        <div className="friends_main-container">
          <img className="hero" src={FriendsHero} alt="friends-hero" />
          <ReferralInfo
            isLoading={isLoadingUserReferralConfig}
            referralLink={referralLink}
            disableCopy={disableCopy}
            shareOptions={shareOptions}
            referralConfig={referralConfig}
            onCopyClick={handleCopyClick}
          />
          <div className="bottom-container">
            {/* Referrer View */}
            {referrer && <ReferralItem data={referrer} referralConfig={referralConfig} />}
            <MyReferrals
              inProgressReferees={referees}
              completedReferrals={completedReferrals}
              referralConfig={referralConfig}
            />
            {!userReferralConfig?.referralConfig && (
              <InfluencerInfo onApplyClick={handleApplyClick} />
            )}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default Friends
