import { useEffect, useRef, useState } from 'react'
import { Typography } from '@mui/material'

//constants
import { COINS_DIVIDER, PIGS_DIVIDER, VIRTUAL_CURRENCY_TYPE } from 'constants/index'
//types
import { ValueFrameProps } from 'types'
//utils
import { createImagesArray, formatNumber, getHeaderImageDiv } from 'utils/utils'

const ValueFrame = ({ value, isPig, isModal, coinsFly, style }: ValueFrameProps) => {
  const [imagesArray, setImagesArray] = useState([])

  const imageRef = useRef(null)

  const formattedValue = formatNumber(value)

  useEffect(() => {
    if (coinsFly) {
      const divider = isPig ? PIGS_DIVIDER : COINS_DIVIDER

      createImagesArray(
        value / divider,
        setImagesArray,
        `/images/${isPig ? 'pig' : 'coin'}.png`,
        getHeaderImageDiv(isPig),
        imageRef.current,
        0.5,
        isPig ? VIRTUAL_CURRENCY_TYPE.PIGGY_BANK : VIRTUAL_CURRENCY_TYPE.COIN
      )
    }
  }, [coinsFly])

  return (
    <div className={`valueGradientWrap ${isModal ? 'modal' : ''}`} style={style}>
      <Typography variant="body2">
        {formattedValue} <img src={`/images/${isPig ? 'pig' : 'coin'}.png`} ref={imageRef} />{' '}
        {imagesArray}
      </Typography>
    </div>
  )
}

export default ValueFrame
