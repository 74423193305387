import { Typography } from '@mui/material'

//utils
import { formatNumber } from 'utils/utils'
//styles
import './index.css'
//types
import { RewardPointsProps } from 'types'

const RewardPoints = ({
  value,
  isPig,
  isLocked,
  showBG,
  avoidResponsiveness,
  type = 'normal',
}: RewardPointsProps) => {
  const formattedValue = formatNumber(value)

  return (
    <div
      className={`reward-points ${isLocked && 'locked'} ${type === 'big' && 'big'} ${
        showBG && 'show-bg'
      } ${avoidResponsiveness && 'avoid'} ${type === 'referral' && 'referral'}`}
    >
      {avoidResponsiveness ? (
        <span>{formattedValue}</span>
      ) : (
        <Typography variant="body2">{formattedValue}</Typography>
      )}
      <img src={`/images/${isPig ? 'pig' : 'coin'}.png`} />
    </div>
  )
}

export default RewardPoints
