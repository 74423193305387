import React, { useState, MouseEvent } from 'react'
import { Button } from '@mui/material'

//styles
import './index.css'
//types
import { BottomButtonProps } from 'types'
//utils
import { matchInChildren } from 'utils/utils'

const BottomButton = ({
  className = '',
  handleClick,
  children,
  style = {},
  disabled = false,
  type = 'button',
  autoBlock = false,
  buttonRef,
}: BottomButtonProps) => {
  const [isInternalDisabled, setIsInternalDisabled] = useState(false)

  const handleButtonClick = async (event: MouseEvent<HTMLButtonElement>) => {
    if (disabled || isInternalDisabled) {
      return
    }

    if (autoBlock || matchInChildren(children, 'claim')) {
      setIsInternalDisabled(true)
    }
    try {
      if (handleClick) {
        await handleClick(event)
      }
    } catch (error) {
      setIsInternalDisabled(false)
    } finally {
      // If the animation is interrupted or an error occurs with the comment, the button will remain available.
      setTimeout(() => setIsInternalDisabled(false), 10000)
    }
  }

  return (
    <Button
      ref={buttonRef}
      className={className}
      variant="contained"
      sx={{ ...styles.button(disabled || isInternalDisabled), ...style }}
      onClick={handleButtonClick}
      disabled={disabled || isInternalDisabled} // Combine external and internal disabled states
      type={type}
    >
      {children}
    </Button>
  )
}

const styles = {
  button: (disabled: boolean) => ({
    width: '100%',
    padding: '16px',
    borderRadius: '72px',
    textTransform: 'none',
    color: '#292D38',
    boxShadow: 'none',
    border: '1px solid transparent',
    ...(disabled
      ? {
          background: '#D5D5D5',
          '&:hover': { background: '#D5D5D5' },
        }
      : {
          background:
            'linear-gradient(168.92deg, #FFE079 6.85%, #FFAE16 90.71%) padding-box, linear-gradient(93.2deg, #FFDA6D 16.28%, #FFCB68 92.47%) border-box',
          boxShadow: 'none',
          '&:hover': {
            background:
              'linear-gradient(93.2deg, #FFDA6D 16.28%, #FFCB68 92.47%) padding-box, linear-gradient(93.2deg, #FFDA6D 16.28%, #FFCB68 92.47%) border-box',
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        }),
  }),
}

export default BottomButton
