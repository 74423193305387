import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

//constants
import QueryKeys from 'constants/queryKeys'
//contexts
import LoaderContext from 'context/LoaderContext'
//services
import { getGiftCardById } from 'services/giftCardsService'
//types
import { calculateDenominationValues, GiftCard } from '@playback-rewards/shared-libraries'
//utils
import { handleError } from 'utils/utils'

const useQueryGetGiftCardById = (
  giftCardId: string | undefined,
  denomination: string | undefined,
  setValidDenomination: React.Dispatch<React.SetStateAction<boolean>>,
  setPiggyBanksValue: React.Dispatch<React.SetStateAction<number>>
) => {
  const { hideLoader } = useContext(LoaderContext)

  const { data, isFetching } = useQuery<AxiosResponse<GiftCard, Error>>(
    [QueryKeys.GET_GIFT_CARD, giftCardId],
    () => getGiftCardById(giftCardId),
    {
      enabled: Boolean(giftCardId) && Boolean(denomination),
      onSuccess: (response) => {
        const giftCard = response?.data
        const giftCardDenomination = giftCard?.denomination.find(
          ({ value }) => value === Number(denomination)
        )
        if (!giftCardDenomination) {
          setValidDenomination(false)
          return
        }

        const discount = giftCardDenomination?.discount

        const { piggyBanksValue } = calculateDenominationValues(
          giftCardDenomination.value,
          discount,
          giftCard.fee,
          giftCard.minFeeValue
        )

        setPiggyBanksValue(piggyBanksValue)

        hideLoader()
      },
      onError: (err) => {
        hideLoader()
        handleError(err)
      },
    }
  )

  return { giftCard: data?.data, isFetching }
}

export default useQueryGetGiftCardById
