import { useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import {
  convertPlainObjectFieldsToFirestoreTypes,
  PlaytimeBatchInfo,
  TaskType,
} from '@playback-rewards/shared-libraries'
import { useQueryClient } from 'react-query'
import _ from 'lodash'

import GenericPlaytimeTask from 'pages/GameDetails/components/SpecialOffer/components/GenericOffer/GenericPlaytimeTask'
import {
  checkIfPlaytimeTaskCompleted,
  getActiveIndex,
  isLockedGenericTask,
  isGenericTaskHidden,
  isGenericTaskNotStarted,
} from 'utils/genericOffer'

import { GAME_CARD_OFFER_TYPE, OFFER_QUALITY, OFFER_TYPE } from '../../constants'
import Task from '../Task'
import { isActivatedOfferIsExpired } from '../../utils/offerCard'
import GenericMMPPurchaseTask from '../../pages/GameDetails/components/SpecialOffer/components/GenericOffer/GenericMMPPurchaseTask'
import QualityTag from './QualityTag'

const OfferCardFooter = ({ type, isValidating, activeTask, quality, game, activatedOffer }) => {
  if (type === GAME_CARD_OFFER_TYPE.ACTIVATED) {
    if (isValidating) {
      return <QualityTag qualityName={OFFER_QUALITY.FEATURED} text="Validating install" />
    }
    if (isActivatedOfferIsExpired(activatedOffer)) {
      return <></>
    }

    return <ActiveOfferTask activeTask={activeTask} activatedOffer={activatedOffer} />
  }

  return (
    <>
      <QualityTag qualityName={quality} />
      {game.isReferral && (
        <Typography variant="body2" sx={styles.text}>
          {game.valueStatement}
        </Typography>
      )}
    </>
  )
}

const ActiveOfferTask = ({ activeTask, activatedOffer }) => {
  const queryClient = useQueryClient()

  const playtimeBatchInfo = useMemo(() => {
    if (!activatedOffer || !activatedOffer?.batchPlaytimeConfig) {
      return
    }
    // TODO: We should convert activatedOffer when we get it from api.
    // But this requries refactoring how we implmented the timestamps.
    // This change is required to support npm shared package
    const activateOfferCopy = _.cloneDeep(activatedOffer)
    convertPlainObjectFieldsToFirestoreTypes(activateOfferCopy)
    return new PlaytimeBatchInfo(activateOfferCopy)
  }, [activatedOffer])
  const batches = useMemo(
    () => Array.from({ length: activatedOffer?.batchPlaytimeConfig?.batchCount || 0 }),
    [activatedOffer?.batchPlaytimeConfig?.batchCount]
  )

  const [activePlaytimeBatchTaskIndex, setActivePlaytimeBatchTaskIndex] = useState(-1)
  useEffect(
    () => setActivePlaytimeBatchTaskIndex(getActiveIndex(batches, playtimeBatchInfo)),
    [batches, activatedOffer, playtimeBatchInfo]
  )
  const isCompletedPlaytimeBatch = useMemo(
    () =>
      checkIfPlaytimeTaskCompleted(
        activatedOffer?.batchPlaytimeConfig,
        playtimeBatchInfo,
        activePlaytimeBatchTaskIndex
      ),
    [activatedOffer?.batchPlaytimeConfig, playtimeBatchInfo, activePlaytimeBatchTaskIndex]
  )
  const playtimeTaskIndex = useMemo(() => {
    if (isCompletedPlaytimeBatch) {
      return Math.min(activePlaytimeBatchTaskIndex + 1, batches.length - 1)
    }
    return activePlaytimeBatchTaskIndex
  }, [isCompletedPlaytimeBatch, activePlaytimeBatchTaskIndex])

  // MMP and Purchase tasks
  const activeGenericTask = useMemo(
    () =>
      activatedOffer.tasks?.find(
        (task) =>
          task.taskType !== TaskType.PLAYTIME &&
          !task.completed &&
          !isGenericTaskNotStarted(task) &&
          !isGenericTaskHidden(task, true) &&
          !isLockedGenericTask(task, activatedOffer.tasks)
      ),
    [activatedOffer.tasks]
  )

  if (activatedOffer.offerType === OFFER_TYPE.GENERIC_OFFER) {
    if (activePlaytimeBatchTaskIndex > -1 && !isCompletedPlaytimeBatch) {
      return (
        <GenericTaskContainer>
          <GenericPlaytimeTask
            key={`GenericOfferBatch ${activePlaytimeBatchTaskIndex + playtimeTaskIndex}`}
            index={playtimeTaskIndex}
            activeIndex={activePlaytimeBatchTaskIndex}
            batchPlaytimeConfig={activatedOffer?.batchPlaytimeConfig}
            playtimeBatchInfo={playtimeBatchInfo}
            onRefresh={() =>
              setActivePlaytimeBatchTaskIndex(getActiveIndex(batches, playtimeBatchInfo))
            }
            hideUI={isCompletedPlaytimeBatch}
            isInstalled={true}
          />
        </GenericTaskContainer>
      )
    }
    if (activeGenericTask) {
      return (
        <GenericTaskContainer>
          <GenericMMPPurchaseTask
            isInstalled={true}
            allMMPTasks={activatedOffer.tasks}
            mmpTask={activeGenericTask}
            onRefresh={() => queryClient.invalidateQueries('featuredGameOffers')}
          />
        </GenericTaskContainer>
      )
    }
  }
  if (activeTask) {
    return (
      <GenericTaskContainer>
        <Task {...activeTask} />
      </GenericTaskContainer>
    )
  }

  return null
}

const GenericTaskContainer = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <div className="genericOffer reduceBottomMargin">{children}</div>
    </Box>
  )
}

const styles = {
  text: {
    color: '#ffffff',
    margin: 'auto 0',
  },
}

export default OfferCardFooter
