import { Container, Typography } from '@mui/material'
import { PIGGY_BANKS_MULTIPLIER } from '@playback-rewards/shared-libraries'
//components
import RedemptionHistoryItem from './Item'
//constants
import { CURRENCY_SYMBOL } from 'constants/index'
//styles
import './index.css'
//types
import { RedemptionHistoryProps } from 'types'

const RedemptionHistory = ({ redemptions }: RedemptionHistoryProps) => {
  return (
    <>
      <Container maxWidth="sm" sx={styles.container}>
        <div className="accountBalanceRedemptionInner">
          <Typography variant="h3" sx={styles.text}>
            Redemption History
          </Typography>
          <div className="accountBalanceRedemptionContent">
            {!redemptions?.length && (
              <Typography variant="body2" sx={styles.text}>
                All of your historical gift card redemptions show up here. You don’t have any yet,
                so get out there and start earning some rewards!
              </Typography>
            )}
            {redemptions?.map(
              ({ id, giftCard, claimedAt, currency, totalPrice, spentCoins, spentPiggyBanks }) => (
                <RedemptionHistoryItem
                  key={id}
                  title={`${giftCard.name} Gift Card`}
                  claimedAt={claimedAt}
                  value={`${CURRENCY_SYMBOL[currency]}${totalPrice}`}
                  spentCoins={spentCoins}
                  spentPiggyBanks={spentPiggyBanks || PIGGY_BANKS_MULTIPLIER(totalPrice)}
                  imageUrl={giftCard.image}
                />
              )
            )}
          </div>
        </div>
      </Container>
    </>
  )
}

const styles = {
  container: {
    padding: 0,
  },
  text: {
    padding: '0 16px',
  },
}

export default RedemptionHistory
