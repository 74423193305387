import React from 'react'
import { Button } from '@mui/material'

//components
import CopyLink from 'pages/ReferFriend/components/CopyLink'
//styles
import './index.css'
//types
import { ReferralInfoProps } from 'types'

const ReferralInfo = ({
  isLoading,
  referralLink,
  disableCopy,
  shareOptions,
  referralConfig,
  onCopyClick,
}: ReferralInfoProps) => {
  return (
    <div className="referral-info">
      <p className="heading">Referral Program</p>
      <p className="sub-heading">
        Get {referralConfig.referrerBonus.bonusPiggyBanks} <img src="/images/pig.png" /> for each
        person!
      </p>
      <p className="info-text">
        Receive <strong>{referralConfig.referrerBonus.bonusPiggyBanks}</strong>{' '}
        <img src="/images/pig.png" /> for every friend that signs up and earns their first gift
        card. Your friend will get <strong>{referralConfig.refereeBonus.bonusPiggyBanks}</strong>{' '}
        <img src="/images/pig.png" /> bonus at that point as well.
      </p>
      <p className="share-text">Share your personal link with friends:</p>
      <div className="copy-link">
        <CopyLink
          className={`copy-btn ${(disableCopy || !referralLink) && 'disabled'}`}
          referralLink={isLoading ? 'Loading...' : referralLink}
          copyDisabled={disableCopy || !referralLink}
          handleCopy={onCopyClick}
        />
      </div>
      <p className="info-text">OR</p>
      <div className="share-options">
        {shareOptions.map(({ Icon, href, handleClick }, key) => (
          <Button
            key={`share-option${key}`}
            href={href}
            onClick={handleClick}
            disabled={!referralLink}
            className={`option ${!referralLink && 'disabled'}`}
          >
            <Icon />
          </Button>
        ))}
      </div>
    </div>
  )
}

export default ReferralInfo
