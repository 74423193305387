import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Dialog, Typography } from '@mui/material'
import toast from 'react-hot-toast'

//components
import FinishChest from './FinishChest'
import FinishContent from './FinishContent'
import OpenButton from './OpenButton'
import ProgressChest from './ProgressChest'
import Tooltip from './Tooltip'
//contexts
import AuthContext from 'context/AuthContext'
//constants
import { PIGGY_BANK_CHEST_MODAL_STATE as MODAL_STATE } from 'constants/index'
//services
import { buyPiggyBankChest } from 'services/piggyBankChestService'
//styles
import './index.css'
//types
import { PiggyBankChestModalProps, PiggyChestReward } from 'types'
//utils
import { CloseRoundFill, Info } from 'utils/icons'
import { handleHeaderDecrementCounter } from 'utils/animations'

const PiggyBankChestModal = ({
  config,
  showModal,
  hideModal,
  handleClaim,
  setUpdatedUser,
  setShowBackgroundPigsAnimation,
}: PiggyBankChestModalProps) => {
  const { user, updateUserData } = useContext(AuthContext)
  const contentRef = useRef<HTMLElement>(null)
  const claimButtonRef = useRef(null)

  const [chestReward, setChestReward] = useState<PiggyChestReward | null>(null)

  const [showTooltip, setShowTooltip] = useState(false)
  const [state, setState] = useState(MODAL_STATE.START)
  const [contentSize, setContentSize] = useState<{
    height: number | undefined
    width: number | undefined
  }>()
  const [isButtonDisabled, setButtonDisabled] = useState(false)

  const hideTooltip = () => setShowTooltip(false)

  const handleHideModal = async () => {
    if (chestReward) {
      await handleClaim(claimButtonRef.current, chestReward)
    }

    hideModal()
    setTimeout(() => setState(MODAL_STATE.START), 200)
  }

  const handleOpenChest = () => {
    setButtonDisabled(true)
    if (!user) {
      toast.error('Something went wrong! Please try again!')
      return
    }

    if (user.points < config?.price.coins) {
      toast.error('Not enough virtual currency to buy a piggy bank chest')

      setButtonDisabled(false)
      hideModal()
      return
    }

    handleHeaderDecrementCounter(false, user.points, user.points - config.price.coins, 150)

    buyPiggyBankChest()
      .then((res) => {
        const { updatedUser, reward } = res.data

        updateUserData({
          ...updatedUser,
          piggyBanks: user.piggyBanks,
          isChestAvailableForPurchase: user.isChestAvailableForPurchase,
        })
        setUpdatedUser(updatedUser)

        setChestReward(reward)
        setState(MODAL_STATE.PROGRESSION)
        setTimeout(() => setState(MODAL_STATE.FINISH), 1000)
      })
      .catch(hideModal)
      .finally(() => setButtonDisabled(false))
  }

  const onClaim = () => {
    setButtonDisabled(true)
    handleHideModal().finally(() => {
      setButtonDisabled(false)
    })
  }

  useEffect(() => {
    setContentSize({
      height:
        !contentSize?.height || contentSize.height < (contentRef?.current?.offsetHeight ?? 0)
          ? contentRef?.current?.offsetHeight
          : contentSize.height,
      width:
        !contentSize?.width || contentSize.width < (contentRef?.current?.offsetWidth ?? 0)
          ? contentRef?.current?.offsetWidth
          : contentSize.width,
    })
  }, [contentRef?.current, state])

  return (
    <>
      <Tooltip showTooltip={showTooltip} hideTooltip={hideTooltip} chestConfig={config} />
      <Dialog
        open={showModal}
        onClose={handleHideModal}
        sx={styles.modal}
        maxWidth="xs"
        className="gamesListPiggyBankChestModal"
      >
        {state === MODAL_STATE.START && (
          <CloseRoundFill
            customClassName="gamesListPiggyBankChestClose"
            onClick={handleHideModal}
          />
        )}
        <FinishChest
          isVisible={state === MODAL_STATE.FINISH}
          setShowBackgroundPigsAnimation={setShowBackgroundPigsAnimation}
        />
        <ProgressChest state={state} isVisible={state !== MODAL_STATE.FINISH} />
        <Box className="gamesListPiggyBankChestContentWrap" style={contentSize}>
          {state !== MODAL_STATE.PROGRESSION && (
            <Box className="gamesListPiggyBankChestContent" ref={contentRef}>
              {state === MODAL_STATE.START && (
                <>
                  <Typography variant="h1">Piggy Bank Chest</Typography>
                  <OpenButton
                    onClick={handleOpenChest}
                    chestConfig={config}
                    disabled={isButtonDisabled}
                  />
                  <Typography variant="h4" sx={styles.text}>
                    Win up to 10,000 Piggy Banks!
                    <Info onClick={() => setShowTooltip((current) => !current)} />
                  </Typography>
                </>
              )}
              {state === MODAL_STATE.FINISH && (
                <FinishContent
                  claimButtonRef={claimButtonRef}
                  handleClaim={onClaim}
                  chestReward={chestReward}
                  disabled={isButtonDisabled}
                />
              )}
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  )
}

const styles = {
  modal: {
    '& .MuiPaper-root': {
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  text: {
    fontWeight: 400,
    gap: '10px',
    display: 'flex',
    width: 'max-content',
    margin: 'auto',
  },
}

export default PiggyBankChestModal
