import React, { useContext, useState, useRef, useEffect } from 'react'
import { Alert, Typography } from '@mui/material'

//components
import ButtonWithFloatingText from 'components/ButtonWithFloatingText'
import ValueFrame from 'components/valueFrame'
import RewardItem from './RewardItem'
//constants
import { ANIMATION_LEVELS } from 'constants/index'
//contexts
import AuthContext from 'context/AuthContext'
//services
import { claimAllRewards } from 'services/userRewardsService'
//styles
import './index.css'
//types
import { AggregatedRewardProps, User } from 'types'
//utils
import { createVirtualCurrencyImagesArrays, handleError } from 'utils/utils'
import { getAnimationLevel, handleHeaderCounter } from 'utils/animations'

const AggregatedReward = ({
  rewards,
  aggregatedCoins,
  aggregatedPiggyBanks,
  setShowBackgroundPigsAnimation,
}: AggregatedRewardProps) => {
  const { updateUserData } = useContext(AuthContext)
  const [pendingUpdate, setPendingUpdate] = useState<User | null>(null)

  const [coinsArray, setCoinsArray] = useState([])
  const [pigsArray, setPigsArray] = useState([])
  const [isSwiping, setIsSwiping] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const [shouldBeRendered, setShouldBeRendered] = useState(true)
  const [shouldHideAlerts, setShouldHideAlerts] = useState(false)

  const containerRef = useRef<HTMLDivElement | null>(null)

  // Calculate the container’s max-height after rendering
  useEffect(() => {
    const container = containerRef.current
    if (container) {
      container.style.maxHeight = `${container.scrollHeight + 20}px`
    }
  }, [rewards])

  useEffect(() => {
    if (pendingUpdate && !shouldBeRendered) {
      updateUserData(pendingUpdate)
      setPendingUpdate(null)
    }

    return () => {
      if (pendingUpdate) {
        updateUserData(pendingUpdate)
      }
    }
  }, [pendingUpdate, shouldBeRendered])

  const handleClaim = async (sourceDiv: EventTarget | null) => {
    const animationLevel = getAnimationLevel(aggregatedPiggyBanks)

    claimAllRewards()
      .then((res) => setPendingUpdate(res.data.updatedUser))
      .catch(handleError)

    createVirtualCurrencyImagesArrays(
      sourceDiv,
      aggregatedCoins,
      aggregatedPiggyBanks,
      setCoinsArray,
      setPigsArray,
      0.7,
      animationLevel
    )

    const displayVFXBehindCurrencyIcon = animationLevel >= ANIMATION_LEVELS.LEVEL_2
    setTimeout(() => {
      handleHeaderCounter(false, aggregatedCoins, displayVFXBehindCurrencyIcon)
      handleHeaderCounter(true, aggregatedPiggyBanks, displayVFXBehindCurrencyIcon)
    }, 700)

    if (animationLevel >= ANIMATION_LEVELS.LEVEL_3) {
      setShowBackgroundPigsAnimation(true)
    }

    setShouldHideAlerts(true)
    setTimeout(() => setIsSwiping(true), 1250)
    setTimeout(() => setIsHidden(true), 2250)
    setTimeout(() => setShouldBeRendered(false), 2600)
  }

  if (!shouldBeRendered) {
    return null
  }

  return (
    <>
      <Typography variant="body2">{coinsArray}</Typography>
      <Typography variant="body2">{pigsArray}</Typography>
      <Alert
        ref={containerRef}
        severity="info"
        icon={false}
        className={`aggregatedReward ${isSwiping ? 'alertSwipe' : ''} ${
          isHidden ? 'hiddenWithSlide' : ''
        }`}
      >
        <div className="topSection">
          <div className="virtualCurrencyRewards">
            {aggregatedPiggyBanks > 0 && (
              <ValueFrame value={aggregatedPiggyBanks} isPig={true} isModal={false} />
            )}
            {aggregatedCoins > 0 && (
              <ValueFrame value={aggregatedCoins} isPig={false} isModal={false} />
            )}
          </div>
          <ButtonWithFloatingText
            customClassName="actionButton"
            onClick={(event: Event) => handleClaim(event.currentTarget)}
            piggyBanks={aggregatedPiggyBanks}
            coins={aggregatedCoins}
          >
            Claim All!
          </ButtonWithFloatingText>
        </div>
        <div className="rewardsSection">
          {rewards?.map((reward, index) => (
            <RewardItem key={index} reward={reward} shouldBeHidden={shouldHideAlerts} />
          ))}
        </div>
      </Alert>
    </>
  )
}

export default AggregatedReward
