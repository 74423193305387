import { axiosBackendInstance } from '../axios'
import authAxios from '../authAxios'
import axiosWebInstance from '../axiosWebInstance'

export const getFeaturedGameOffers = (userId) => {
  return authAxios.get(`/api/v1/users/${userId}/featuredGames`)
}

export const claimAggregatedReward = async (offerId) => {
  return axiosBackendInstance.put(`/api/v1/rewards/claim/aggregated/${offerId}`)
}

export const createSpecialReward = async (offerId, data) => {
  return axiosBackendInstance.post(`/api/v1/rewards/special-rewards/${offerId}`, data)
}

export const claimSpecialReward = async (offerId, data) => {
  return axiosBackendInstance.patch(`/api/v1/rewards/claim/special-rewards/${offerId}`, data)
}

export const claimPurchaseReward = async (rewardId) => {
  return axiosBackendInstance.put(`/api/v1/rewards/claim/purchase/${rewardId}`)
}

export const getAllUnclaimedRewards = async () => {
  return axiosBackendInstance.get(`/api/v1/rewards/unclaimed/all`)
}

export const claimAllRewards = async () => {
  return axiosWebInstance.post(`/api/v1/rewards/claim/all`)
}
