import React from 'react'
import { Dialog, Typography } from '@mui/material'

//styles
import './index.css'
//types
import { PiggyBankChestModalTooltipProps } from 'types'
//utils
import { formatNumber } from 'utils/utils'

const PiggyBankChestModalTooltip = ({
  showTooltip,
  hideTooltip,
  chestConfig,
}: PiggyBankChestModalTooltipProps) => {
  const DROP_RATES_MULTIPLIER = 100

  return (
    <Dialog
      open={showTooltip}
      onClose={hideTooltip}
      sx={styles.tooltip}
      maxWidth="xs"
      className="gamesListPiggyBankChestModalTooltip"
    >
      <Typography variant="inherit">Piggy Bank Chest Odds</Typography>
      <Typography variant="body2">
        Below is the probability of the Piggy Bank payouts within a single Piggy Bank Chest.
      </Typography>
      <table className="gamesListPiggyBankChestModalTooltipTable">
        <thead>
          <tr>
            <th>
              <Typography variant="body2">Piggy Bank Payouts</Typography>
            </th>
            <th>
              <Typography variant="body2">Probability</Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {chestConfig?.rewards.map(({ reward, dropRate }) => (
            <tr key={`odds-${reward.piggyBanks}`}>
              <th>
                <Typography variant="body2">{formatNumber(reward.piggyBanks)}</Typography>
              </th>
              <th>
                <Typography variant="body2">
                  {Number(dropRate * DROP_RATES_MULTIPLIER).toFixed(2)}%
                </Typography>
              </th>
            </tr>
          ))}
          <tr>
            <th></th>
          </tr>
        </tbody>
      </table>
    </Dialog>
  )
}

const styles = {
  tooltip: {},
}

export default PiggyBankChestModalTooltip
