import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'

import { FreeMode, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useNavigate } from 'react-router-dom'

import InfoFeeModal from '../../GamesList/components/InfoFeeModal'
import useCardsSlider from '../../../hooks/useCardsSlider'
import { BackArrow as ArrowIcon, Info } from '../../../utils/icons'
import { reorderGiftCards, sendGA4Event } from '../../../utils/utils'
import { ANALYTICS_EVENT } from '../../../constants'
import GiftCard from './GiftCard'

const GiftCardsRow = ({ giftCard }) => {
  const [denominations, setDenominations] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    setDenominations(reorderGiftCards(giftCard?.denomination))
  }, [])

  const handleShowModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  const swiper = useCardsSlider(denominations?.length)

  const navigate = useNavigate()

  const handleSwiperClick = (swiper, { target }) => {
    const denomination = target.closest('.giftCardListCard')?.dataset?.denomination
    if (denomination) {
      sendGA4Event(ANALYTICS_EVENT.SELECT_ITEM.GIFT_CARD, {
        item_id: `${_.snakeCase(giftCard.name)}_${denomination}`,
        item_brand: giftCard.name,
        price: denomination,
      })

      navigate(`/rewards/${giftCard.id}/${denomination}`)
    }
  }

  const sliderSettings = {
    className: 'gamesListOffersRowSlider',
    freeMode: true,
    modules: [FreeMode, Navigation],
    spaceBetween: 20,
    slidesPerView: 1,
    navigation: true,
    width: 216,
    ref: swiper.positionRef,
    onSwiper: swiper.setSwiper,
    onClick: handleSwiperClick,
    onSlideChange: swiper.changeButtonStates,
  }

  if (!denominations?.length) {
    return null
  }

  return (
    <>
      <Typography variant="inherit" sx={styles.title(giftCard.fee)}>
        {giftCard.name}
      </Typography>
      {giftCard.fee && (
        <Typography variant="h4" sx={styles.textFee}>
          Costs include additional fees charged by {giftCard.name}{' '}
          <Info onClick={handleShowModal} />
        </Typography>
      )}
      <div ref={swiper.wrapperRef} className="giftCardsRow">
        <div
          className={`gamesListOffersRowArrow ${!swiper.isPrevEnabled ? 'disabled' : ''} prev`}
          onClick={() => swiper.handleClickArrow(false)}
        >
          <ArrowIcon />
        </div>
        <Swiper {...sliderSettings}>
          {denominations.map((denomination, index) => (
            <SwiperSlide key={index}>
              <GiftCard
                giftCard={giftCard}
                denomination={denomination.value}
                discount={denomination.discount}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={`gamesListOffersRowArrow ${!swiper.isNextEnabled ? 'disabled' : ''} next`}
          onClick={swiper.handleClickArrow}
        >
          <ArrowIcon />
        </div>
      </div>
      <InfoFeeModal
        showModal={isModalOpen}
        toggleModal={handleCloseModal}
        giftCardName={giftCard.name}
        fee={giftCard.fee}
        minFeeValue={giftCard.minFeeValue}
      />
    </>
  )
}

const styles = {
  title: (fee) => ({
    marginBottom: fee ? '2px' : '12px',
    paddingLeft: '20px',
  }),
  textFee: {
    color: '#636874',
    fontWeight: 400,
    paddingLeft: '20px',
    fontSize: '15px',
    marginBottom: '10px',
  },
}

export default GiftCardsRow
