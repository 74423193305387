import { useContext, useRef } from 'react'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import toast from 'react-hot-toast'

//contexts
import AuthContext from 'context/AuthContext'
//services
import { resetDailyStreak } from 'services/dailyStreak'
import { getUserById } from 'services/userService'

const useMutationResetStreak = (
  updateStreakStatus: () => void,
  setShowExpiringPopup: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user, updateUserData } = useContext(AuthContext) as any
  const isResettingRef = useRef(false)

  const { mutate: resetStreakMutateRaw } = useMutation(resetDailyStreak, {
    onSuccess: async () => {
      const data = await getUserById(user.id)
      updateUserData(data.data)
      updateStreakStatus()
      setShowExpiringPopup(false)
      isResettingRef.current = false
    },
    onError: (error) => {
      toast.error(
        ((error as AxiosError)?.response?.data as Error).message || 'Something went wrong!'
      )
      isResettingRef.current = false
    },
  })

  const resetStreakMutate = (...args: Parameters<typeof resetStreakMutateRaw>) => {
    if (isResettingRef.current) {
      return
    }
    isResettingRef.current = true
    resetStreakMutateRaw(...args)
  }

  return { resetStreakMutate }
}

export default useMutationResetStreak
