import React, { useContext, useEffect } from 'react'
import { Alert, Box, Container, Typography } from '@mui/material'

//components
import EarnGiftCards from 'components/EarnGiftCards'
import Layout from 'components/layout/Details'
import PageInner from 'components/layout/PageInner'
import RedemptionHistory from './components/RedemptionHistory'
//contexts
import AuthContext from 'context/AuthContext'
import LoaderContext from 'context/LoaderContext'
//hooks
import useQueryGetAllUserOrders from 'hooks/useQueryGetAllUserOrders'
//styles
import './index.css'
//types
import { AuthContextType } from 'types'
//utils
import { formatNumber } from 'utils/utils'

const AccountBalance = () => {
  const { user } = useContext(AuthContext) as AuthContextType

  const { redemptions, isFetching } = useQueryGetAllUserOrders(user?.id)

  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  useEffect(() => {
    isFetching ? showLoader() : hideLoader()
  }, [isFetching])

  const formattedPoints = formatNumber(user?.points)
  const formattedPiggyBanks = formatNumber(user?.piggyBanks)

  const BALANCE_COINS = [
    {
      name: 'Piggy Banks',
      value: formattedPiggyBanks,
      className: 'priceText pig',
      imgSrc: '/images/pig.png',
    },
    {
      name: 'Coins',
      value: formattedPoints,
      className: 'priceText coin',
      imgSrc: '/images/coin.png',
    },
  ]

  return (
    <>
      <PageInner customClassName={isLoading ? 'blured' : ''}>
        <Layout title="Account balance">
          <Container maxWidth="sm">
            <Box sx={styles.coins}>
              {BALANCE_COINS.map(({ name, value, className, imgSrc }) => (
                <Alert key={name} sx={styles.coinAlert} severity="info" icon={false}>
                  <Typography variant="h4">{name}</Typography>
                  <Typography
                    variant="inherit"
                    sx={styles.coinAlertValue}
                    className={`accountBalanceItem ${className}`}
                  >
                    <img src={imgSrc} /> {value}
                  </Typography>
                </Alert>
              ))}
            </Box>
            <div className="accountBalanceExplain">
              <div className="accountBalanceExplainText">
                <Typography variant="h1">How to earn rewards</Typography>
                <EarnGiftCards isBalance={true} />
              </div>
              <div className="accountBalanceExplainText">
                <Typography variant="h1">How to get gift cards</Typography>
                <Typography variant="h4" sx={styles.text}>
                  <br />
                  Use <img src="/images/pig.png" /> to redeem gift cards. <br />
                  <br />
                </Typography>
                <Typography
                  variant="h4"
                  className="accountBalanceGetResultText"
                  sx={styles.getResultText}
                >
                  <div className="valueGradientWrap accountBalanceValueWrap">
                    <Typography variant="body2" className="priceText pig">
                      100 <img src={`/images/pig.png`} />
                    </Typography>
                  </div>
                  = <strong>$1</strong> in gift cards
                </Typography>
              </div>
            </div>
          </Container>

          <RedemptionHistory redemptions={redemptions} />
        </Layout>
      </PageInner>
    </>
  )
}

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  headerText: {
    color: '#27272A',
  },
  coins: {
    display: 'flex',
    gap: '12px',
  },
  coinAlert: {
    borderRadius: '12px',
    background: '#FFFFFF',
    width: '100%',
    '& .MuiAlert-message': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  coinAlertValue: {
    marginTop: '12px',
    textAlign: 'left',
  },
  getResultText: {
    fontFamily: 'var(--poppins-font)',
    fontWeight: 400,
    color: '#000000',
    strong: {
      fontWeight: 700,
    },
  },
  text: {
    color: '#000000',
    fontWeight: 400,
  },
}

export default AccountBalance
