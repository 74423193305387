import axios from 'axios'

import { APP_PLATFORM, LOCAL_STORAGE } from './constants'
import { refreshBundle, errorInterceptorHandler, generateId } from './utils/utils'

export const axiosBackendInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  timeout: 25000,
  withCredentials: true,
})

axiosBackendInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(LOCAL_STORAGE.JWT_TOKEN)
  await refreshBundle()
  if (token) {
    config.headers.authorization = `Bearer ${token}`
  }

  // Add other headers
  config.headers['x-platform'] = window.ReactNativeWebView
    ? APP_PLATFORM.NATIVE_APP
    : APP_PLATFORM.WEB
  config.headers['x-request-id'] = generateId(12)

  return config
})

axiosBackendInstance.interceptors.response.use((response) => response.data, errorInterceptorHandler)
