import axios from 'axios'
import { LOCAL_STORAGE } from './constants'
import { refreshBundle, errorInterceptorHandler, generateId } from './utils/utils'

const authAxios = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  timeout: 25000,
})

authAxios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(LOCAL_STORAGE.JWT_TOKEN)
  await refreshBundle()
  if (!token) {
    window.location.href = '/auth'
  }

  config.headers.authorization = `Bearer ${token}`
  config.headers['x-request-id'] = generateId(12)

  return config
})

authAxios.interceptors.response.use((response) => response.data, errorInterceptorHandler)

export default authAxios
