import { GiftCard } from '@playback-rewards/shared-libraries'
import { axiosBackendInstance } from '../axios'
import axiosWebInstance from '../axiosWebInstance'
import { AxiosResponse } from 'axios'

export const getAllGiftCards = async (): Promise<
  AxiosResponse<{ giftCards: GiftCard[]; minRedeem: number }, Error>
> => {
  return axiosBackendInstance.get('/api/v1/gift-cards/')
}

export const getGiftCardById = async (
  giftCardId?: string
): Promise<AxiosResponse<GiftCard, Error>> => {
  if (!giftCardId) {
    throw new Error('giftCardId is required')
  }

  return axiosWebInstance.get(`/api/v1/gift-cards/${giftCardId}`)
}
