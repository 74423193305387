import { AxiosResponse } from 'axios'

import { axiosBackendInstance } from '../axios'
import axiosWebInstance from '../axiosWebInstance'
import { UpdatedUserResponse, User } from 'types'

export const getUserQuests = () => {
  return axiosBackendInstance.get(`/api/v1/quests`)
}

export const getUserQuestsHistory = (pagination: Record<string, string>) => {
  const queryString = new URLSearchParams(pagination).toString()
  return axiosBackendInstance.get(`/api/v1/quests/history?${queryString}`)
}
export const getSuggestedQuest = async () => {
  return axiosBackendInstance.get('/api/v1/quests/suggested')
}

export const claimQuestReward = async (
  questId: string
): Promise<AxiosResponse<UpdatedUserResponse, Error>> => {
  return axiosWebInstance.put(`/api/v1/quests/claim/${questId}`)
}

export const refreshUserQuests = async (user: User, skipCondition = false) => {
  const questsCanBeRefreshed = Object.values(user.questNextRefreshTime).some(
    (refreshTime) => refreshTime._seconds < new Date().getTime() / 1000
  )

  if (questsCanBeRefreshed || skipCondition) {
    return axiosBackendInstance.post(`/api/v1/quests/${user.id}/refresh`)
  }
}

export const updateQuestPlayTimeProgress = async (unifiedAppId: string) => {
  return axiosBackendInstance.patch(`/api/v1/quests/progress/playtime/${unifiedAppId}`)
}
