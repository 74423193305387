import React, { useContext, useMemo, useRef, useState } from 'react'
import { Typography, Modal } from '@mui/material'

//assets
import Timer from 'assets/offers/timer.png'
//components
import FireIcon from '../FireIcon'
import BottomButton from 'components/common/BottomButton'
import PointInput from 'components/PointInput'
//constants
import { ANIMATION_LEVELS } from 'constants/index'
//contexts
import AuthContext from 'context/AuthContext'
//styles
import './index.css'
//services
import { getUserById } from 'services/userService'
//types
import { DailyStreakClaimPopupProps } from 'types'
//utils
import { counter, getAnimationLevel, handleHeaderCounter } from 'utils/animations'
import { createVirtualCurrencyImagesArrays } from 'utils/utils'

const DailyStreakClaimPopup = ({
  open,
  streakCount,
  dailyReward,
  streakReadyIn,
  setShowBackgroundPigsAnimation,
  onClose,
}: DailyStreakClaimPopupProps) => {
  const { user, updateUserData } = useContext(AuthContext)
  const inputRef = useRef<HTMLDivElement>(null)
  const isBonusDay = useMemo(() => dailyReward > 0, [dailyReward])
  const [pigsArray, setPigsArray] = useState([])

  const handleOnAnimationStart = () => {
    if (!inputRef.current || dailyReward <= 0) {
      return
    }

    counter(inputRef.current, 0, dailyReward, null, true, null, null, 100)
  }

  const handleClaimButton = async (sourceDiv: EventTarget | null) => {
    onClose()

    if (dailyReward <= 0) {
      return
    }

    const animationLevel = getAnimationLevel(dailyReward)

    createVirtualCurrencyImagesArrays(
      sourceDiv,
      0,
      dailyReward,
      null,
      setPigsArray,
      0.7,
      animationLevel
    )

    const response = await getUserById(user?.id)

    const displayVFXBehindCurrencyIcon = animationLevel >= ANIMATION_LEVELS.LEVEL_2
    const dividerForCounter = dailyReward < 20 ? 50 : 100
    setTimeout(() => {
      handleHeaderCounter(true, dailyReward, displayVFXBehindCurrencyIcon, dividerForCounter, () =>
        updateUserData(response.data)
      )
    }, 700)
  }

  return (
    <>
      <Typography variant="body2">{pigsArray}</Typography>
      <Modal
        open={open}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className="dscp_main-container">
          <div className="fire-icon">
            <FireIcon
              type="enabled"
              width={144}
              value={streakCount}
              animate
              onAnimationStart={handleOnAnimationStart}
              onAnimationEnd={() => dailyReward > 0 && setShowBackgroundPigsAnimation(true)}
            />
          </div>
          <div className="content">
            <p className="title">{isBonusDay ? 'Level Reward' : 'Streak Extended'}</p>
            {isBonusDay ? (
              <PointInput
                inputRef={inputRef}
                isPig={true}
                points={0}
                customClassName="gamesHeaderInput"
              />
            ) : (
              <p className="info">Check in tomorrow to increase your Daily Streak!</p>
            )}
            <BottomButton
              className="actionButton"
              handleClick={(event: Event) => handleClaimButton(event.currentTarget)}
            >
              {isBonusDay ? 'Claim!' : 'Continue'}
            </BottomButton>
            <p className="time-to-reset">
              Extend Streak in: <img style={{ transform: 'translateY(20%)' }} src={Timer} />{' '}
              {streakReadyIn}
            </p>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DailyStreakClaimPopup
