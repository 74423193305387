import { useMemo, useState } from 'react'
import { Typography } from '@mui/material'

//assets
import FireAnimation from 'assets/dailyStreak/fire-animation.gif'
import FireEnabled from 'assets/dailyStreak/fire-enabled.svg'
import FireDisabled from 'assets/dailyStreak/fire-disabled.svg'
import FireExpiring from 'assets/dailyStreak/fire-expiring.svg'
//styles
import './index.css'
//types
import { FireIconProps } from 'types'

const FireIcon = ({
  type,
  width = 53,
  value,
  avoidBoldText = false,
  isHeader = false,
  animate = false,
  onAnimationStart,
  onAnimationEnd,
}: FireIconProps) => {
  const [isSlideAnimComplete, setIsSlideAnimComplete] = useState(false)
  const fontSize = useMemo(() => Number(width) * 0.45, [width])
  const fireIcon = useMemo(() => {
    if (type === 'enabled') {
      return FireEnabled
    } else if (type === 'disabled') {
      return FireDisabled
    } else if (type === 'expiring') {
      return FireExpiring
    }
  }, [type])

  return (
    <div style={{ width }} className={`fire-icon ${isHeader && 'header'} `}>
      {animate && (
        <img
          src={FireAnimation}
          alt="fire-animation"
          className={`fire-animation ${isSlideAnimComplete && 'animate'}`}
          style={{ width }}
        />
      )}
      <img
        src={fireIcon}
        alt="fire-icon"
        className={`${animate && isSlideAnimComplete && 'animate'}`}
      />

      {isHeader ? (
        <Typography
          className="value"
          variant={'h4'}
          sx={{ fontWeight: 700, color: type === 'disabled' ? 'black' : 'white' }}
        >
          {value}
        </Typography>
      ) : (
        <>
          <span
            className={`value ${animate && 'animate'}`}
            style={{
              color: type === 'disabled' ? 'black' : 'white',
              fontSize,
              fontWeight: avoidBoldText ? '600' : 'bold',
            }}
            onAnimationStart={onAnimationStart}
            onAnimationEnd={() => setIsSlideAnimComplete(true)}
          >
            {animate ? (value || 0) - 1 : value}
          </span>
          {isSlideAnimComplete && (
            <span
              className={`value2 ${animate && 'animate'}`}
              style={{
                color: type === 'disabled' ? 'black' : 'white',
                fontSize,
                fontWeight: avoidBoldText ? '600' : 'bold',
              }}
              onAnimationEnd={onAnimationEnd}
            >
              {value}
            </span>
          )}
        </>
      )}
    </div>
  )
}

export default FireIcon
