/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, Dispatch, SetStateAction } from 'react'
import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'

//contexts
import AuthContext from 'context/AuthContext'
//services
import { dailyStreakAdWatched } from 'services/dailyStreak'

const useMutationAdWatched = (
  setShowClaimPopup: Dispatch<SetStateAction<boolean>>,
  setShowBackgroundPigsAnimation: Dispatch<SetStateAction<boolean>>
) => {
  const { user, updateUserData } = useContext(AuthContext) as any
  const { mutate: adWatchedMutate, isLoading: isLoadingAdWathced } = useMutation(
    dailyStreakAdWatched,
    {
      onSuccess: async (data) => {
        const updatedUser: any = { ...data.data }
        updatedUser.piggyBanks = user.piggyBanks
        updateUserData(updatedUser)
        setShowBackgroundPigsAnimation(false)
        setShowClaimPopup(true)
      },
      onError: (error) => {
        toast.error(
          ((error as AxiosError)?.response?.data as Error).message || 'Something went wrong!'
        )
      },
    }
  )
  return { adWatchedMutate, isLoadingAdWathced }
}

export default useMutationAdWatched
