import { useContext, useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Typography } from '@mui/material'

//components
import BottomButton from 'components/common/BottomButton'
import ConfirmOrderModal from './components/ConfirmOrderModal'
import Layout from 'components/layout/Details'
import PageInner from 'components/layout/PageInner'
import PriceText from 'components/giftCards/PriceText'
//constants
import { CURRENCY_SYMBOL } from 'constants/index'
//contexts
import AuthContext from 'context/AuthContext'
import LoaderContext from 'context/LoaderContext'
//hooks
import useQueryGetGiftCardById from 'hooks/useQueryGetGiftCardById'
//pages
import NotFound from '../NotFound'
//types
import { AuthContextType } from 'types'
//utils
import { formatNumber } from 'utils/utils'

import './index.css'

/**
 * A page to display the Gift Card for user to purchase
 * The main parameters used to determine the value of a gift card are ID and denomination
 *
 * @component
 * @returns {ReactNode} A React element that renders gift card details page
 */
const GiftCardsDetails = () => {
  const { user } = useContext(AuthContext) as AuthContextType
  const [modal, setModal] = useState(false)
  const [isValidDenomination, setValidDenomination] = useState(true)

  const { giftCardId, denomination } = useParams()

  const [piggyBanksValue, setPiggyBanksValue] = useState(0)

  const { giftCard, isFetching } = useQueryGetGiftCardById(
    giftCardId,
    denomination,
    setValidDenomination,
    setPiggyBanksValue
  )

  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  useLayoutEffect(() => {
    isFetching ? showLoader() : hideLoader()
  }, [isFetching])

  const showConfirmOrderModal = () => setModal(true)
  const hideConfirmOrderModal = () => setModal(false)

  const SubmitButtonText = () => (
    <Typography variant="h4" className="giftCardDetailsSubmitButtonText">
      Buy:{' '}
      <PriceText
        userBalance={user?.piggyBanks}
        giftCardValue={piggyBanksValue}
        value={formatNumber(piggyBanksValue)}
        isPig={true}
      />
    </Typography>
  )

  if (!isValidDenomination || (!isLoading && !giftCard)) {
    return <NotFound />
  }

  return (
    <PageInner>
      {giftCard && (
        <ConfirmOrderModal
          showModal={modal}
          hideModal={hideConfirmOrderModal}
          showLoader={showLoader}
          hideLoader={hideLoader}
          giftCard={giftCard}
          denomination={denomination}
          formattedPiggiesValue={formatNumber(piggyBanksValue)}
          piggyBanksValue={piggyBanksValue}
        />
      )}
      <Layout title="Redeem Gift Card">
        <Container maxWidth="sm" sx={styles.container}>
          <div className={`giftCardDetailsInner ${isLoading ? 'blured' : ''}`}>
            <div className="giftCardDetailsContent">
              <img alt={giftCard?.name} src={giftCard?.image} className="giftCardDetailsImage" />
              <Typography variant="h2">{giftCard?.name} Gift Card</Typography>
              <Typography variant="h1">
                {CURRENCY_SYMBOL[giftCard?.currency || 'USD']}
                {denomination} {giftCard?.currency}
              </Typography>
              <Typography
                variant="body2"
                className="giftCardDetailsDescription"
                dangerouslySetInnerHTML={{ __html: `<small>${giftCard?.terms}</small>` }}
              />
            </div>
            <BottomButton handleClick={showConfirmOrderModal} style={styles.closeButton}>
              <SubmitButtonText />
            </BottomButton>
          </div>
        </Container>
      </Layout>
    </PageInner>
  )
}

const styles = {
  container: {
    padding: 0,
  },
  closeButton: {
    border: '2px solid rgb(251, 146, 60)',
    backgroundColor: '#ffffff',
    color: '#000000',
    marginBottom: '20px',
  },
}

export default GiftCardsDetails
