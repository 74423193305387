import React, { useEffect, useRef } from 'react'

import { getCoordinates, getImagePosition, moveToHead } from '../../utils/animations'

import './index.css'

const MovingImage = ({
  div,
  src,
  index,
  headerDiv,
  animationDuration,
  virtualCurrencyType,
  isSingleVirtualCurrencyClaim,
  alignRight,
}) => {
  const movingImageRef = useRef(null)
  const buttonDivRect = div.getBoundingClientRect()
  const coordinates = getCoordinates(div)

  useEffect(() => {
    setTimeout(() => {
      moveToHead(
        movingImageRef,
        headerDiv,
        buttonDivRect,
        coordinates,
        animationDuration,
        virtualCurrencyType,
        isSingleVirtualCurrencyClaim
      )
    }, index * 30)
  }, [])

  return (
    <img
      src={src}
      className="movingImage"
      ref={movingImageRef}
      style={getImagePosition(coordinates, alignRight)}
    />
  )
}

export default MovingImage
