import React from 'react'
import { Container } from '@mui/system'
import { Typography } from '@mui/material'
import GiftCardsRow from './components/GiftCardsRow'

const Content = ({ giftCards }) => {
  return (
    <div className="giftCardList">
      <Container maxWidth="sm" sx={styles.titleContainer}>
        <div className="giftCardListTitlesInner">
          <Typography variant="h1">Redeem Rewards</Typography>
          <Typography variant="h4" sx={styles.giftCardsText}>
            Spend <img src="/images/pig.png" /> to redeem gift cards!
          </Typography>
        </div>
      </Container>

      <Container maxWidth="sm" sx={styles.giftCardsContainer}>
        <div className="giftCardListInner">
          {giftCards?.map((giftCard, index) => (
            <GiftCardsRow key={index} giftCard={giftCard} />
          ))}
        </div>
      </Container>
    </div>
  )
}

const styles = {
  titleContainer: {
    padding: '0',
  },
  giftCardsContainer: {
    padding: 0,
  },
  giftCardsText: {
    padding: '8px 0 20px',
    fontWeight: 400,
    color: '#000000',
  },
}

export default Content
