import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Container } from '@mui/material'
import { useQuery } from 'react-query'

import AuthContext from '../../context/AuthContext'
import LoaderContext from '../../context/LoaderContext'
import HistoryContext from '../../context/HistoryContext'
import { handleError, openGameLink } from '../../utils/utils'
import Layout from '../../components/layout/Games'
import SlideLayout from '../../components/layout/Games/Slide'
import { PAGINATION_LIMIT, LOCAL_STORAGE, QUEST_TYPE, REWARD_HISTORY_TYPE } from '../../constants'
import {
  getUserQuests,
  getUserQuestsHistory,
  refreshUserQuests,
} from '../../services/questsService'
import RewardHistory from '../../components/rewardHistory'
import Paginator from '../../components/Pagination'
import useLocalStorage from '../../hooks/useLocalStorage'
import BackgroundPigsAnimation from '../../components/BackgroundPigsAnimation'
import Items from './components/Items'
import NoItems from './components/NoItems'

import './index.css'

const PREV_ROUTES = ['/games', '/library', '/rewards']

const QuestsList = () => {
  const { user, updateUserData } = useContext(AuthContext)
  const { previousPage } = useContext(HistoryContext)

  const [userQuests, setUserQuests] = useState()
  const [history, setHistory] = useState([])
  const [questsFound, setQuestsFound] = useState(false)
  const [showBackgroundPigsAnimation, setShowBackgroundPigsAnimation] = useState(false)

  const { getItem, removeItem } = useLocalStorage()

  const { isLoading: loadingRefreshQuests } = useQuery(
    'assignActiveQuests',
    () => refreshUserQuests(user),
    {
      onSuccess: (data) => data && updateUserData(data.updatedUser),
      onError: handleError,
    }
  )

  const paginationParams = {
    limit: PAGINATION_LIMIT,
    cursor: history?.length ? history[history.length - 1].id : '',
  }

  const { isFetching: loadingUnclaimedRewards } = useQuery(
    'userQuests',
    () => getUserQuests(paginationParams),
    {
      onSuccess: (quests) => {
        setUserQuests(quests)
        let questsFound = false
        Object.entries(QUEST_TYPE).map(([, value]) => {
          questsFound = questsFound || quests[value]?.length
        })

        setQuestsFound(questsFound)
      },
      onError: handleError,
    }
  )

  const {
    data: questsHistory,
    isFetching: loadingQuestHistory,
    refetch: refetchHistory,
  } = useQuery('questHistory', () => getUserQuestsHistory(paginationParams), {
    onSuccess: (questsHistory) => {
      setHistory(_.unionBy(history, questsHistory?.histories, 'id'))
    },
    refetchOnWindowFocus: false,
    onError: handleError,
  })

  const isFetching = loadingUnclaimedRewards || loadingRefreshQuests || loadingQuestHistory
  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  useEffect(() => {
    const mmp = JSON.parse(getItem(LOCAL_STORAGE.ONBOARDING_MMP))

    if (mmp) {
      removeItem(LOCAL_STORAGE.ONBOARDING_MMP)
      openGameLink(mmp.mmpLink, user, mmp.gameDetails)
    }
  }, [])

  useEffect(() => {
    isFetching ? showLoader() : hideLoader()
  }, [isFetching])

  const noQuests = !userQuests || !questsFound
  const isFooterVisible = history?.length > 0 || noQuests

  return (
    <Layout isLoading={isLoading}>
      {showBackgroundPigsAnimation && <BackgroundPigsAnimation />}
      <SlideLayout
        prevRoutes={PREV_ROUTES}
        direction={previousPage === PREV_ROUTES[0] ? 'left' : 'right'}
      >
        <Container maxWidth="sm" sx={styles.container}>
          {userQuests &&
            Object.entries(QUEST_TYPE).map(([key, value]) => (
              <Items
                key={QUEST_TYPE[key]}
                type={QUEST_TYPE[key]}
                quests={userQuests[value]}
                setShowBackgroundPigsAnimation={setShowBackgroundPigsAnimation}
              />
            ))}

          {isFooterVisible && (
            <div className="questsListFooter">
              {noQuests && <NoItems />}
              {history?.length > 0 && (
                <>
                  <RewardHistory rewards={history} type={REWARD_HISTORY_TYPE.QUESTS} />
                  <Paginator hasNextPage={questsHistory?.hasNextPage} loadMore={refetchHistory} />
                </>
              )}
            </div>
          )}
        </Container>
      </SlideLayout>
    </Layout>
  )
}

const styles = {
  container: {
    padding: '0 0 20px',
    'h1, h2, h3, h4, h5, p, span': {
      fontFamily: 'var(--poppins-font)',
    },
    '.questsListItemCompletedDate': {
      fontFamily: 'var(--poppins-font)',
      color: '#27272A',
      margin: 'auto 0',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    height: '100%',
    minHeight: 'calc(100svh - 150px)',
    '@media (min-width: 640px)': {
      gap: '16px',
    },
  },
}

export default QuestsList
