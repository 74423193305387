import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import toast from 'react-hot-toast'

//components
import MessageLayout from 'components/layout/Message'
//constants
import {
  ERROR_TEXT,
  LOCAL_STORAGE,
  PRIVACY_POLICY_LINK,
  TERMS_OF_SERVICE_LINK,
} from 'constants/index'
//contexts
import AuthContext from 'context/AuthContext'
import LoaderContext from 'context/LoaderContext'
//hooks
import useTOSPromptConfig from 'hooks/useTOSPromptConfig'
//services
import { acceptToS } from 'services/tosPromptService'
import { updatePromptViewed } from 'services/appUsageService'
//utils
import { showTOSPromptForUser } from 'utils/utils'

const AcceptTOSPrompt = () => {
  const { user, updateUserData } = useContext(AuthContext)
  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  const navigate = useNavigate()

  const { config: TOSPromptConfig } = useTOSPromptConfig()
  useEffect(() => {
    if (!showTOSPromptForUser(user, TOSPromptConfig?.updatedAt)) {
      navigate('/games')
      hideLoader()
    }
    if (isLoading) {
      hideLoader()
    }
  }, [])

  const handleClick = async () => {
    let deviceData = null

    const stringifiedDeviceData = localStorage.getItem(LOCAL_STORAGE.BACKEND_DEVICE_DATA)
    if (stringifiedDeviceData) {
      deviceData = JSON.parse(stringifiedDeviceData)
    }

    try {
      showLoader()

      const data = await acceptToS()
      updateUserData(data.data.updatedUser)

      if (deviceData && deviceData.showAppUsagePrompt) {
        await updatePromptViewed(deviceData.userId, deviceData.uniqueId, false)
        navigate('/app-usage-permission')
        return
      }

      navigate('/games')
    } catch (err) {
      toast.error(ERROR_TEXT.default)
    } finally {
      hideLoader()
    }
  }

  const EXISTING_USER_TOS_PROMPT_PART = 'Our Terms of Service & Privacy Policy have been updated.'

  return (
    <MessageLayout
      title={'Accept ToS and Privacy Policy'}
      button={{
        text: 'Accept',
        handleClick,
      }}
      isLoading={isLoading}
    >
      <Typography variant="body2">
        {user?.acceptedTOS !== null ? EXISTING_USER_TOS_PROMPT_PART : ''} By using Playback Rewards,
        you confirm that you agree to our <a href={TERMS_OF_SERVICE_LINK}>Terms of Service</a> and{' '}
        <a href={PRIVACY_POLICY_LINK}>Privacy Policy</a>. Playback Rewards is only available to
        people ages 18 and older. You authorize and direct us to utilize your Linked Accounts to
        deliver the Playback Rewards Services to you.
      </Typography>
    </MessageLayout>
  )
}

export default AcceptTOSPrompt
