import React from 'react'
import Modal from '@mui/material/Modal'

//components
import FireIcon from '../FireIcon'
//styles
import './index.css'

const DailyStreakWebPopup = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className="dswp_main-container">
        <div className="fire-icon">
          <FireIcon type="enabled" width={144} />
        </div>
        <div className="content">
          <img
            className="close-btn"
            src="/images/modalCloseButton.png"
            alt="close"
            onClick={onClose}
          />
          <p className="title">Only Available on Android!</p>
          <p className="sub-title">
            This feature is only available on Android. Log in to your Playback account on your
            Android device to start your daily streak!
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default DailyStreakWebPopup
