import React, { useEffect, useState } from 'react'

//styles
import './index.css'
//types
import { FinishChestProps } from 'types'

const FinishChest = ({ isVisible, setShowBackgroundPigsAnimation }: FinishChestProps) => {
  const [isPigVisible, setPigVisible] = useState(false)
  const [isShineVisible, setShineVisible] = useState(false)
  const [height, setHeight] = useState<string | number>('unset')

  useEffect(() => {
    if (isVisible) {
      setPigVisible(true)

      const element = document.getElementsByClassName(
        'gamesListPiggyBankChestProgress'
      )[0] as HTMLElement
      setHeight(element.offsetHeight)

      setTimeout(() => {
        setShineVisible(true)
        setShowBackgroundPigsAnimation(true)
      }, 500)
    }
  }, [isVisible])

  return (
    <div
      className={`gamesListPiggyBankChestFinishDivWrap ${isVisible ? 'visible' : ''}`}
      style={{ height }}
    >
      <div className="gamesListPiggyBankChestFinishDiv">
        <div className={`gamesListPiggyBankChestShine ${isShineVisible ? 'visible' : ''}`}>
          <img src="/images/pigChestShine.png" />
        </div>
        <div className="gamesListPiggyBankChestFinishDivChest">
          <img src="/images/pigChestOpenback.png" className="gamesListPiggyBankChestOpenback" />
          <img
            src="/images/pigChestPig.png"
            className={`gamesListPiggyBankChestPig ${isPigVisible ? 'visible' : ''}`}
          />
          <img src="/images/pigChestOpenBottom.png" className="gamesListPiggyBankChestOpenBottom" />
        </div>
      </div>
    </div>
  )
}

export default FinishChest
