import axios from 'axios'

//constants
import { APP_PLATFORM, LOCAL_STORAGE } from 'constants/index'

//utils
import { errorInterceptorHandler, generateId, refreshBundle } from 'utils/utils'

const axiosWebInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  timeout: 25000,
  withCredentials: true,
})

axiosWebInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(LOCAL_STORAGE.JWT_TOKEN)

  await refreshBundle()
  if (token) {
    config.headers.authorization = `Bearer ${token}`
  }

  config.headers['x-platform'] = window.ReactNativeWebView
    ? APP_PLATFORM.NATIVE_APP
    : APP_PLATFORM.WEB
  config.headers['x-request-id'] = generateId(12)

  return config
})
axiosWebInstance.interceptors.response.use((response) => response, errorInterceptorHandler)

export default axiosWebInstance
