import './config/firebaseTimestampConfig'
import { useEffect } from 'react'
import { ThemeProvider } from '@mui/system'
import * as Sentry from '@sentry/react'
import { decodeToken } from 'react-jwt'

import {
  Routes,
  Route,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'

import { ref, onChildChanged } from 'firebase/database'
import { db } from './config/firebaseConfig'
import AuthProvider from './providers/AuthProvider'
import { ADMIN_ROUTES, NOT_AUTHORIZED_ROUTES, PROTECTED_ROUTES, PUBLIC_ROUTES } from './routes'
import { MAIN_THEME } from './utils/muiThemes'
import HistoryProvider from './providers/HistoryProvider'
import { NotFound } from './pages'
import LoaderProvider from './providers/LoaderProvider'

import Toaster from './components/CustomToaster'
import { EnvAdapter } from './utils/envAdapter'
import useLocalStorage from './hooks/useLocalStorage'
import { CHECK_BUNDLE_TIME, ERRORS, LOCAL_STORAGE, SERVICE } from './constants'
import { initializeTagManager } from './utils/utils'
import GlobalError from './pages/GlobalError'

import './App.css'
import { UserGeoDataProvider } from './context/UserGeoData/UserGeoDataProvider'
import { TOSPromptConfigProvider } from './context/TOSPropmpt/TOSPromptConfigProvider'
import { NativeAppProvider } from './context/NativeApp/NativeAppProvider'
import DeltaTimeProvider from 'context/DeltaTimeContext'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment:
    (window.ReactNativeWebView ? 'webview-' : '') + process.env.REACT_APP_SENTRY_ENVIRONMENT,
  tracesSampleRate: EnvAdapter.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  profilesSampleRate: EnvAdapter.REACT_APP_SENTRY_PROFILES_SAMPLE_RATE,
  enabled: process.env.REACT_APP_SENTRY_ENABLED === SERVICE.ON,
  replaysOnErrorSampleRate: EnvAdapter.REACT_APP_SENTRY_REPLAYS_SAMPLE_RATE,
  beforeSend(event, hint) {
    const error = hint.originalException
    if (
      error?.name === 'AxiosError' &&
      (error?.response?.status === 401 || error?.response?.status === 403)
    ) {
      return null
    }

    return event
  },
})

const App = () => {
  const { getItem } = useLocalStorage()

  useEffect(() => {
    initializeTagManager()
    // clear cache after new deploy process
    const clearCacheRef = ref(db, 'clear_cache_action')
    const versionRef = ref(db, 'version')

    onChildChanged(clearCacheRef, () => {
      localStorage.setItem(LOCAL_STORAGE.LAST_SCRIPT_CHECK, String(Date.now() - CHECK_BUNDLE_TIME))
    })

    onChildChanged(versionRef, (snapshot) => {
      const version = snapshot.val()
      let userId = null
      const token = getItem(LOCAL_STORAGE.JWT_TOKEN)
      if (token) {
        userId = decodeToken(token)?.id
      }
      if (version !== process.env.REACT_APP_VERSION) {
        Sentry.captureEvent({
          message: ERRORS.CACHE,
          level: 'error',
          extra: { userId },
        })
      }
    })
  }, [])

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

  return (
    <LoaderProvider>
      <ThemeProvider theme={MAIN_THEME}>
        <NativeAppProvider>
          <HistoryProvider>
            <AuthProvider>
              <TOSPromptConfigProvider>
                <UserGeoDataProvider>
                  <DeltaTimeProvider>
                    <SentryRoutes>
                      {[
                        ...PUBLIC_ROUTES,
                        ...PROTECTED_ROUTES,
                        ...NOT_AUTHORIZED_ROUTES,
                        ...ADMIN_ROUTES,
                      ].map(({ path, Component }) => (
                        <Route
                          key={path}
                          path={path}
                          element={
                            <Sentry.ErrorBoundary
                              fallback={<GlobalError />}
                              key={path + '-boundary'}
                              showDialog
                            >
                              <Component />
                            </Sentry.ErrorBoundary>
                          }
                          errorElement={<GlobalError />}
                        />
                      ))}
                      <Route path="*" element={<NotFound />} />
                    </SentryRoutes>
                    <Toaster />
                  </DeltaTimeProvider>
                </UserGeoDataProvider>
              </TOSPromptConfigProvider>
            </AuthProvider>
          </HistoryProvider>
        </NativeAppProvider>
      </ThemeProvider>
    </LoaderProvider>
  )
}

export default App
