import { useMemo, MouseEvent } from 'react'
import { Typography } from '@mui/material'
import { DailyStreakStatus } from '@playback-rewards/shared-libraries'

//assets
import Timer from 'assets/offers/timer.png'
import Info from 'assets/dailyStreak/info.svg'
//components
import RewardPoints from 'components/RewardPoints'
import BottomButton from 'components/common/BottomButton'
import FireIcon from '../FireIcon'
import DailyStreakProgressBar from '../DailyStreakProgressBar'
//styles
import './index.css'
//types
import { DailyStreakCardProps } from 'types'

const DailyStreakCard = ({
  isWeb,
  streakStatus,
  streakCount = 0,
  streakReadyIn,
  streakExpiresIn,
  streakConfig,
  disableClick,
  onTileClick,
  onButtonClick,
}: DailyStreakCardProps) => {
  const subTitle = useMemo(() => {
    if (isWeb) {
      return 'This feature is only available on Android devices.'
    }
    if (streakStatus === DailyStreakStatus.CLAIMED) {
      return (
        <span>
          Extend in:{' '}
          <span className="time-to-reset">
            <img src={Timer} /> {' ' + streakReadyIn}
          </span>
        </span>
      )
    }
    if (streakStatus === DailyStreakStatus.EXPIRING) {
      return (
        <span>
          Your streak has <span className="expired">expired</span> and will reset soon. There’s
          still time to save it!
        </span>
      )
    }
    return 'Watch an ad to extend your streak!'
  }, [streakStatus, streakReadyIn])
  const buttonText = useMemo(
    () => (streakStatus === DailyStreakStatus.EXPIRING ? 'Save!' : 'Go!'),
    [streakStatus]
  )

  return (
    <div className="dsc_main-container" onClick={onTileClick}>
      {streakStatus === DailyStreakStatus.EXPIRING && (
        <div className="revive-timer">
          <img src={Timer} />
          Streak resets: <strong>{streakExpiresIn}</strong>
        </div>
      )}
      <div className={`dsc_container ${streakStatus === DailyStreakStatus.EXPIRING && 'showTime'}`}>
        <div className={`dsc_content_container`}>
          <FireIcon
            type={streakStatus === DailyStreakStatus.EXPIRING ? 'expiring' : 'enabled'}
            value={streakCount}
          />
          <div className="title-container">
            <Typography variant="h4" className="title">
              Daily Streak
            </Typography>
            <Typography variant="body2" className="sub-title">
              {subTitle}
            </Typography>
          </div>
          <div className={`rewards ${streakStatus === DailyStreakStatus.CLAIMED && 'info'}`}>
            {streakStatus === DailyStreakStatus.CLAIMED && <img src={Info} alt="info" />}
            {streakStatus !== DailyStreakStatus.CLAIMED && (
              <BottomButton
                className="actionButton"
                disabled={isWeb || disableClick}
                handleClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                  onButtonClick && onButtonClick()
                }}
              >
                {buttonText}
              </BottomButton>
            )}
          </div>
        </div>
        {streakStatus === DailyStreakStatus.READY_FOR_AD && (
          <DailyStreakProgressBar
            className="progress-bar"
            streakCount={streakCount}
            streakConfig={streakConfig}
          />
        )}
      </div>
    </div>
  )
}

export default DailyStreakCard
