import React, { useState, useEffect } from 'react'

//styles
import './index.css'
//types
import { PigIcon } from 'types'

const BackgroundPigsAnimation = () => {
  const [pigs, setPigs] = useState<Array<PigIcon>>([])

  useEffect(() => {
    const numPigs = 15

    // Generate evenly spaced positions across the screen width
    const leftPositions = Array.from({ length: numPigs }, (_, i) => (i / numPigs) * 90)

    // Shuffle positions to avoid clustering
    for (let i = leftPositions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[leftPositions[i], leftPositions[j]] = [leftPositions[j], leftPositions[i]]
    }

    // Generate pigs with staggered delays and different speeds
    const newPigs = leftPositions.map((left, index) => {
      const delay = index * 0.1 // Staggered appearance
      const duration = Math.random() * 0.5 + 1.25 // Randomized float speed

      return {
        id: index,
        size: Math.random() * 135 + 70, // Random sizes
        left: left + (Math.random() * 20 - 20), // Small additional horizontal variation
        delay,
        duration,
      }
    })
    setPigs(newPigs)

    setTimeout(() => setPigs([]), 2900)
  }, [])

  return (
    <div className="pigContainer">
      {pigs.map((pig) => (
        <img
          key={pig.id}
          src="/images/pig.png"
          className="flyingPig"
          style={{
            animationDuration: `${pig.duration}s`,
            animationDelay: `${pig.delay}s`,
            width: `${pig.size}px`,
            height: `${pig.size}px`,
            left: `${pig.left}%`,
          }}
        />
      ))}
    </div>
  )
}

export default BackgroundPigsAnimation
