import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef } from 'react'

//components
import Button from 'components/common/BottomButton'
import PointInput from 'components/PointInput'
//contexts
import AuthContext from 'context/AuthContext'
//hooks
import useTimer from 'hooks/useTimer'
//types
import { FinishContentProps } from 'types'
//utils
import { counter } from 'utils/animations'
import { Clock } from 'utils/icons'

const FinishContent = ({
  claimButtonRef,
  handleClaim,
  chestReward,
  disabled,
}: FinishContentProps) => {
  const { user } = useContext(AuthContext)
  const inputRef = useRef<HTMLDivElement>(null)

  const timer = useTimer(user?.chestNextRefreshTime)

  useEffect(() => {
    if (inputRef.current?.textContent) {
      const value = +inputRef.current.textContent.replace(/,/g, '')
      counter(inputRef.current, value, chestReward?.piggyBanks, null, true, null, null, 100)
    }
  }, [inputRef.current])

  return (
    <>
      <Typography variant="h1">You won!</Typography>
      <PointInput
        inputRef={inputRef}
        isPig={true}
        points={0}
        customInnerClassName="gamesListPiggyBankChestInputInner"
        customClassName="gamesListPiggyBankChestInput"
        inputVariant="h1"
      />
      <Box className="gamesListPiggyBankChestFinishFoot">
        <Button
          buttonRef={claimButtonRef}
          handleClick={handleClaim}
          style={styles.claimButton}
          disabled={disabled}
        >
          Claim!
        </Button>
        <Typography variant="h4" sx={styles.availableTimer}>
          New chest available in: <Clock /> {timer}
        </Typography>
      </Box>
    </>
  )
}

const styles = {
  claimButton: {
    width: '100%',
    padding: '12px 8px',
  },
  availableTimer: {
    color: '#94A3B8',
    fontWeight: 400,
    svg: {
      path: {
        fill: '#94A3B8',
      },
    },
  },
}

export default FinishContent
