import React, { useMemo } from 'react'

//styles
import './index.css'
//types
import { ReferralItemInfoTextProps } from 'types'

const ReferralItemInfoText = ({
  refereeName,
  isMyReferrerView,
  isReadyToRedeem,
  pendingToEarn,
  bonusInUSD,
}: ReferralItemInfoTextProps) => {
  const infoText = useMemo(() => {
    if (isReadyToRedeem) {
      return isMyReferrerView ? (
        <>
          Redeem a gift card to earn your <strong>${bonusInUSD} referral bonus</strong>
        </>
      ) : (
        <>
          {refereeName} needs to redeem a gift card for you to earn <strong>${bonusInUSD}</strong>
        </>
      )
    }
    return isMyReferrerView ? (
      <>
        Earn <strong>{pendingToEarn}</strong> more <img src="/images/pig.png" /> to collect your{' '}
        <strong>${bonusInUSD}</strong> referral bonus!
      </>
    ) : (
      <>
        {refereeName} needs <strong>{pendingToEarn}</strong> more <img src="/images/pig.png" /> to
        redeem their first gift card
      </>
    )
  }, [isReadyToRedeem, pendingToEarn, bonusInUSD])

  return <p className={`info-text ${isReadyToRedeem && 'ready-to-redeem'}`}>{infoText}</p>
}

export default ReferralItemInfoText
