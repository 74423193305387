import { Typography } from '@mui/material'
import React from 'react'

//styles
import './PriceText.css'
//types
import { PriceTextProps } from 'types'

const PriceText = ({
  userBalance,
  giftCardValue,
  value,
  discountedGiftCardValue,
  discountedValue,
  isPig,
}: PriceTextProps) => {
  const textGradient = isPig ? 'pig' : 'coin'
  const imgSrc = isPig ? '/images/pig.png' : '/images/coin.png'

  const getTextColor = (checkDiscountedValue = false) => {
    const valueToCompare = checkDiscountedValue ? discountedGiftCardValue : giftCardValue
    if (userBalance !== undefined && valueToCompare !== undefined && userBalance < valueToCompare) {
      return 'red'
    }
    return textGradient
  }

  return (
    <div className="priceText-container">
      <Typography
        variant="h4"
        className={`priceText ${getTextColor()}  ${discountedValue ? 'unbold discount' : ''}`}
      >
        {value} {!discountedValue && <img src={imgSrc} />}
      </Typography>
      {discountedGiftCardValue && (
        <Typography variant="h4" className={`priceText ${getTextColor(true)}`}>
          {discountedValue} {<img src={imgSrc} />}
        </Typography>
      )}
    </div>
  )
}

export default PriceText
