import React, { useContext } from 'react'
import { Typography } from '@mui/material'

//components
import BottomButton from 'components/common/BottomButton'
import { MaybeLaterButton } from './MaybeLaterButton'
//constants
import {
  IN_APP_REVIEWS_ANDROID_VERSION_CODE,
  PLAYBACK_ANDROID_APP_ID,
  REACT_NATIVE_STATUS,
} from 'constants/index'
//contexts
import NativeAppContext from 'context/NativeApp/NativeAppContext'
//types
import { RateInGooglePlayContentProps } from 'types'
//utils
import { sendRNMessage } from 'utils/utils'

export const RateInGooglePlayContent = ({ hideModal }: RateInGooglePlayContentProps) => {
  const { nativeAppDetails } = useContext(NativeAppContext)

  const handleRateAction = () => {
    hideModal()

    if (
      nativeAppDetails &&
      Number(nativeAppDetails.versionCode) >= IN_APP_REVIEWS_ANDROID_VERSION_CODE
    ) {
      sendRNMessage({
        status: REACT_NATIVE_STATUS.SHOW_IN_APP_REVIEW_PROMPT,
      })
    } else {
      sendRNMessage({
        status: REACT_NATIVE_STATUS.OPEN_URL,
        url: `https://play.google.com/store/apps/details?id=${PLAYBACK_ANDROID_APP_ID}`,
      })
    }
  }

  return (
    <>
      <Typography variant="h1">Thanks!</Typography>
      <Typography variant="body1">
        Please also rate our app in Google Play to help others find us.
      </Typography>
      <Typography variant="body1">We really appreciate it!</Typography>

      <BottomButton className="actionButton" handleClick={handleRateAction}>
        Rate on Google Play
      </BottomButton>

      <MaybeLaterButton hideModal={hideModal} />
    </>
  )
}
