import _ from 'lodash'
import { captureEvent } from '@sentry/react'
import {
  ClickContext,
  generateOfferLink,
  MmpLinkBody,
  MMPProvider,
} from '@playback-rewards/shared-libraries'

//axios
import { axiosBackendInstance } from '../axios'
//constants
import { USER_PLATFORM, ERRORS } from 'constants/index'
//services
import { getDeviceData } from './userService'
//utils
import { getUserPlatform, handleError } from 'utils/utils'

export const buildMMPClickDetails = async (clickId: string, user: any, game: any, ip: string) => {
  if (!game.specialOfferDetails) {
    return game.gameDetails.url
  }

  const encodedUserAgent = encodeURIComponent(window.navigator.userAgent)

  const deviceData = await getDeviceData(user.id)
  const adId = _.get(deviceData, 'aaid', '')

  const offerLink = game.specialOfferDetails.mmpLink
  const campaignId = game.specialOfferDetails.offerCampaignId

  const mmpClickDetails: ClickContext = {
    ip,
    userAgent: encodedUserAgent,
    clickId: clickId,
    userId: user.id,
    adId,
    mmpProvider: game.specialOfferDetails.mmpProvider,
    mmpLink: offerLink,
    isUAOffer: game.specialOfferDetails.isUAOffer,
    campaignName: game.specialOfferDetails.publisherCampaignName,
    campaignId: campaignId.substring(0, 24),
    offerId: game.specialOfferDetails.id,
  }

  if (campaignId.length > 24) {
    captureEvent({
      message: ERRORS.MMP_LINK,
      level: 'error',
      extra: {
        err: 'The campaignId is > 24 characters and being truncated',
        offerLink,
        campaignId,
      },
    })
  }

  const clickableOfferLink = generateOfferLink(mmpClickDetails)
  if (
    Object.values(MMPProvider).includes(mmpClickDetails.mmpProvider) &&
    mmpClickDetails.mmpProvider !== MMPProvider.NONE &&
    clickableOfferLink === mmpClickDetails.mmpLink
  ) {
    captureEvent({
      message: ERRORS.MMP_LINK,
      level: 'error',
      extra: { err: 'The MMP link does not substitute any parameters', offerLink },
    })
  }

  return {
    clickId,
    userId: user.id,
    campaignId,
    name: game.specialOfferDetails.gameName,
    unifiedAppId: game.specialOfferDetails.unifiedAppId,
    clickedLink: clickableOfferLink,
    userAgent: window.navigator.userAgent,
    adid: adId !== '' ? adId : null,
    offerId: game.specialOfferDetails.id,
    mmpProvider: game.specialOfferDetails.mmpProvider,
    offerCampaignName: game.specialOfferDetails.publisherCampaignName,
    linkPlatform: game.specialOfferDetails.platform,
    devicePlatform: getUserPlatform(USER_PLATFORM.DESKTOP),
    isOfferActivated: !!game.activatedOffer,
  }
}

export const saveClickId = async (clickDetails: MmpLinkBody) => {
  return axiosBackendInstance.post(`/api/v1/mmp/click`, clickDetails).catch(handleError)
}
